import React, { useState } from 'react';
import Pagetitle from './PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useAsync from '../hooks/useAsync';
import PackageServices from '../services/PackageServices';
import DeleteButton from './Button/DeleteButton';
import PackageUpdate from './Update Model/UpdatePackage';
import PackageDayUpdate from './Update Model/UpdateDay';
import ViewPackage from './View Model/viewPackage';
import AddDay from './Add Model/AddDay';
import Policy from './Add Model/AddPolicy';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import PackageStatus from './TogelButton/TogleButtonForStatus';

Modal.setAppElement('#root');

function Package() {
  const { data, error, isLoading, run } = useAsync(PackageServices.getPackage);
  // console.log(">>>",data)

  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [IsDayModalOpen, setIsDayModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedAddDay, setSelectedAddDay] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleViewDetails = (pkg) => {
    setSelectedPackage(pkg);
    setIsViewModalOpen(true);
  };
  const handleAddDayDetails = (pkg) => {
    setSelectedAddDay(pkg);
    setIsAddModalOpen(true);
  };
  const handlePolicyDetails = (pkg) => {
    setSelectedPolicy(pkg);
    setIsModalOpen(true);
  };
  const handleEditDetails = (pkg) => {
    setSelectedEdit(pkg);
    setIsEditModalOpen(true); // This should trigger the modal to open
    toggleActionMenu(null);

  };
  const handleDayDetails = (pkg) => {
    setSelectedDay(pkg);
    setIsDayModalOpen(true); // This should trigger the modal to open
    toggleActionMenu(null);

  };


  const handleDelete = (pkg) => {
    setSelectedEdit(pkg);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDayModal = () => {
    setIsAddModalOpen(false);
    setSelectedAddDay(null);
  };
  const closeEditDayModal = () => {
    setIsDayModalOpen(false);
    setSelectedDay(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPackage(null);
  };
  

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };

  const usersPerPage = 10;
  const indexOfLastPackage = currentPage * usersPerPage;
  const indexOfFirstPackage = indexOfLastPackage - usersPerPage;

  const filteredPackages = data?.data?.filter(pkg => {
    const city = pkg?.city || '';
    const name = pkg?.packageName || '';
    const price = pkg?.price?.toString() || '';

    const normalizedCity = city.toLowerCase();
    const normalizedSearchQuery = searchQuery.toLowerCase();

    const matchesSearchQuery = name.toLowerCase().includes(normalizedSearchQuery) ||
      normalizedCity.includes(normalizedSearchQuery) ||
      price.includes(normalizedSearchQuery);

    const matchesCity = selectedCity ? normalizedCity === selectedCity.toLowerCase() : true;

    return matchesSearchQuery && matchesCity;
  });

  const currentPackages = filteredPackages?.slice(indexOfFirstPackage, indexOfLastPackage);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setCurrentPage(1);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      <div className="right_col" role="main">
        <Pagetitle />
        <div className="container-box px-0">
          <div className="container-box-top-header justify-content-end">
            <div className="sub-title-box-right d-flex">
              <Link className="Add-new-btn site-btn-green" to="/admin/add-package">
                <img src="img/plus-circle.svg" className="me-2" alt="" /> Add Package
              </Link>
            </div>
          </div>
          <div className="container-box-inner">
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Destination</th>
                  <th>Amount</th>
                  <th>Start Date</th>
                  <th>Expire Date</th>
                  <th>Add Day</th>
                  <th>Status</th>
                  {/* <th>Summary</th> */}
                  {/* <th>Policy</th> */}
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {currentPackages?.map((pkg, index) => (
                  <tr key={pkg?._id}>
                    <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                    <td>                   
                        <img
                          src={process.env.REACT_APP_URL + pkg.images[0]}
                       
                          style={{ height: '70px', width: '70px', objectFit: 'contain' }}
                        />
                    </td>

                    <td>{pkg?.packageName}</td>
                    <td>{pkg?.destination?.title}</td>
                    <td>{pkg?.price}</td>
                    <td>{formatDate(pkg?.startDate)}</td>
                    <td>{formatDate(pkg?.endDate)}</td>
                    <td>
                      <button className="view-details-btn addDay" onClick={() => handleAddDayDetails(pkg)} >Add Day</button>
                    </td>
                    <td className="status-toggle"> <PackageStatus data={pkg} page="package" onSuccess={run}/></td>
                    
                    {/* <td>
                      <button className="view-details-btn terms" onClick={() => handlePolicyDetails(pkg)} >Policy</button>
                    </td> */}
                    <td>
                      <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(pkg)}>Edit</button></li>
                              <li><button className="summary" onClick={() => handleDayDetails(pkg)}>Summary</button></li>
                              
                              <li><button className="view" onClick={() => handleViewDetails(pkg)}>View</button></li>
                              <li><button className="delete" onClick={() => handleDelete(pkg)}>Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <ReactPaginate
              pageCount={Math.ceil((filteredPackages?.length || 0) / usersPerPage)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              initialPage={currentPage - 1}
              disableInitialCallback={true}
              pageLinkClassName={'page-link'}
              previousLinkClassName={'page-link'}
              nextLinkClassName={'page-link'}
              pageClassName={'page-item'}
              previousClassName={'page-item'}
              nextClassName={'page-item'}
              disabledClassName={'disabled'}
            />
          </div>
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Package"
          // className="modal-content"
          overlayClassName="modal-overlay"
        >
          {selectedEdit && ( // Make sure the package details are loaded before rendering the modal content
            <PackageUpdate pkg={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
          )}
        </Modal>
        <Modal
          isOpen={IsDayModalOpen}
          onRequestClose={closeEditDayModal}
          contentLabel="Edit Day Package"
          // className="modal-content"
          overlayClassName="modal-overlay"
        >
          {selectedDay && ( // Make sure the package details are loaded before rendering the modal content
            <PackageDayUpdate pkg={selectedDay} closeModal={closeEditDayModal} onSuccess={run} />
          )}
        </Modal>

        <Modal
          isOpen={isAddModalOpen}
          onRequestClose={closeDayModal}
          contentLabel="Add Day"
          className="modal-content"
          overlayClassName="modal-overlay"
        ><AddDay pkg={selectedAddDay} closeModal={closeDayModal} onSuccess={run}  />
        </Modal>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <Policy pkg={selectedPolicy} closeModal={closeModal} />
        </Modal>
        <Modal
          isOpen={isViewModalOpen}
          onRequestClose={closeModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <ViewPackage pkg={selectedPackage} closeModal={closeModal} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="Package" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>

    </>
  );
}

export default Package;
