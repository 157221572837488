import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import PackageServices from '../../services/PackageServices';
import Footer from './Common/footer';
import Booking from '../FrontEnd/Booking';
import moment from "moment";


function PackageDetails() {
    const { id } = useParams(); // Get the package ID from the URL
    const decodedId = atob(id);
    const [pkg, setPkg] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const generateDates = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dates = [];

        while (start <= end) {
            dates.push(new Date(start));
            start.setDate(start.getDate() + 1); // Move to the next day
        }

        return dates;
    };


    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null); // To store the selected date
    const [scheduleData, setScheduleData] = useState(null); // To store the schedule data for the selected 
    const dates = generateDates(pkg?.startDate, pkg?.endDate);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    useEffect(() => {
        const fetchPackage = async () => {
            try {
                const response = await PackageServices.getPackageById(decodedId);
                // console.log("response", response); // Log the entire response
                setPkg(response?.data); // Set pkg to the appropriate part of the response
                setValue(response?.data?.schedule[0])
            } catch (err) {
                setError('Error loading package details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPackage();
    }, [id]);

    useEffect(() => {
        // Log the pkg state whenever it updates
        // console.log("pkg", pkg);
        // If pkg is not null and has startDate, set the default selected date
        if (pkg && pkg.startDate) {
            setSelectedDate(moment(pkg?.startDate).format("YYYY-MM-DD"));
        }
    }, [pkg]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    if (!pkg) return null; // Guard clause in case pkg is not yet loaded or there's an error

    const startDate = moment(pkg?.startDate);
    const endDate = moment(pkg?.endDate);

    // Generate array of dates between startDate and endDate
    const totalDays = endDate.diff(startDate, "days") + 1;
    const calendarDates = Array.from({ length: totalDays }, (_, index) =>
        startDate.clone().add(index, "days")
    );

    // Find schedule for the selected date
    const selectedSchedule = pkg?.schedule.find(schedule =>
        moment(schedule.date).format("YYYY-MM-DD") === selectedDate
    );

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with zero if needed
        return day; // Return only the day
    };

    const getMonth = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const month = monthNames[date.getMonth()]; // Get the month name
        return month; // Return only the month
    };

    const handleBookNowClick = () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            navigate('/login'); // If no token found, redirect to login
        } else {
            navigate(`/booking/${id}`); // If token exists, navigate to booking page
        }
    };

    const formattedDatehotel = (inputDate) => {
        if (!inputDate) return "Date not available"; // Handle undefined dates

        const dateObj = new Date(inputDate);
        const nextDateObj = new Date(dateObj);
        nextDateObj.setDate(dateObj.getDate() + 1); // Calculate the next date

        const formattedCurrentDate = dateObj.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });

        const formattedNextDate = nextDateObj.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });

        return `${formattedCurrentDate} - ${formattedNextDate}`;
    };

    const formattedDate = (inputDate) => {
        const date = new Date(inputDate);
        const options = {
            month: 'short',
            day: 'numeric',
            weekday: 'short'
        };

        // Get the formatted date as "Mon, Oct 14"
        const dateString = date.toLocaleDateString('en-US', options);

        // Rearrange the date to be in the desired format: "Oct 14,Mon"
        const [weekday, monthDay] = dateString.split(', '); // Split by the comma
        return `${monthDay},${weekday}`; // Rearrange and remove space
    };

    const handleDateClick = (date, index) => {
        setActiveIndex(index); // Set the clicked date as active
        setValue(date);        // Update the selected value
    };
    // console.log('value', value?.hotel)
    return (

        <>
            <section className="notification-section package-details-section">
                <div className="container">
                    <div className="tour-package-outer">
                        <h3 className="banner-heading">Package Details</h3>
                    </div>
                </div>
            </section>
            <section className="all-package-section">
                <div className="container">
                    <div className="breadcrumb-outer">
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/tour-package"> Tour Package</a>
                            </li>
                            <li>Package Details</li>
                        </ul>
                    </div>
                    <div className="notification-inner">
                        <div className="row g-4">
                            {pkg?.images && pkg?.images.length > 0 ? (
                                <>
                                    <div className="col-md-4">
                                        <div className="package-details-img-outer">
                                            <img
                                                src={`${process.env.REACT_APP_URL || ''}${pkg?.images[0]}`}
                                                alt="Package Main"
                                            />
                                        </div>
                                    </div>
                                    {pkg?.images.slice(1).map((image, index) => (
                                        <div key={index} className="col-md-4">
                                            <div className="package-details-img-outer">
                                                <img
                                                    src={`${process.env.REACT_APP_URL || ''}${image}`}
                                                    alt={`Package image ${index + 1}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div>No images available</div>
                            )}
                        </div>

                        <div className="details-heading-outer">
                            <div className="heading-outer d-flex">
                                <div className="main-heading-outer">
                                    <h3
                                        className="main-heading package-main-heading"
                                        style={{ fontSize: 28, marginBottom: 10 }}
                                    >
                                        {pkg?.packageName}  &nbsp;

                                        <span className=" package-day package-day-1 ">
                                            {pkg?.duration}
                                        </span>
                                    </h3>
                                    <p className="sub-heading" style={{ marginBottom: 10 }}>
                                        {/* 1N Punjab 1N Manali 1N Shimla{" "} */}
                                        {pkg?.startLocation} {pkg?.endLocation}
                                    </p>
                                </div>
                                <div className="view-all-btn">
                                    <div className="modify-outer">
                                        <p className="sub-heading-2">
                                            {pkg?.startLocation} &nbsp; - &nbsp;
                                            {new Date(pkg?.startDate).toLocaleDateString('en-GB', {
                                                day: 'numeric',
                                                month: 'short'
                                            })} -
                                            {new Date(pkg?.endDate).toLocaleDateString('en-GB', {
                                                day: 'numeric',
                                                month: 'short'
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-lg-12">
                                <div className="calendar-inner">
                                    <div className="calendar-outer d-flex" style={{ marginBottom: 12, flexWrap: 'wrap' }}>
                                        <div className="day-plan" style={{ background: "#6d528d", color: "#fff" }}>
                                            <p className="sub-heading-2 text-white">Day Plan</p>
                                        </div>
                                    </div>

                                    {/* <div className="calendar-outer d-flex" style={{ flexWrap: 'wrap' }}>
                                        {pkg?.schedule.map((date, index) => (
                                            <div key={index} className="day-plan" onClick={() => handleDateClick(date)} style={{ cursor: 'pointer' }}>
                                                <p className="sub-heading-2">
                                                    {date?.date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase()} <br />
                                                    {date.getDate()}
                                                </p>
                                            </div>
                                        ))}
                                    </div> */}
                                    <div className="calendar-outer d-flex" style={{ flexWrap: 'wrap' }}>
                                        {pkg?.schedule.map((date, index) => {

                                            const dateObj = new Date(date.date);
                                            const dayName = dateObj.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();

                                            const dayNumber = dateObj.getDate();
                                            return (
                                                // <div key={index} className="day-plan" onClick={() => setValue(date)} style={{ cursor: 'pointer' }}>
                                                <div
                                                    key={index}
                                                    className={`day-plan ${index === activeIndex ? 'active' : ''}`}  // Add active class if the index matches
                                                    onClick={() => handleDateClick(date, index)}
                                                    style={{ cursor: 'pointer', border: index === activeIndex ? '2px solid #0085ff' : 'none' }}  // Apply border to the active item
                                                >
                                                    <p className="sub-heading-2">
                                                        {dayName} <br /> {dayNumber}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>



                                    {/* Display the relevant schedule for the selected date */}
                                    {
                                        scheduleData && (
                                            <div className="day-plan-2">
                                                <p className="sub-heading">
                                                    <a href="#" className="package-day" style={{ backgroundColor: '#FF7029', color: '#fff' }}>
                                                        Day {dates.indexOf(selectedDate) + 1}
                                                    </a>
                                                    {/* Includes: {scheduleData.inclusions || 'Transfer . Sightseeing . Hotel'} */}
                                                </p>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="day-plan-2 justify-content-start">
                                    <p className="sub-heading">
                                        {" "}
                                        <span
                                            className=" package-day"
                                            style={{ backgroundColor: "#FF7029", color: "#fff" }}
                                        >
                                            Day {value?.day}
                                        </span>
                                        &nbsp;
                                        {/* <span
                                            className=" package-day"
                                            style={{ backgroundColor: "rgba(36, 186, 236, 0.2)", color: "#595959" }}
                                        >
                                            {value?.activities && value?.activities.length > 0
                                                ? `${value?.activities.length} Activities`
                                                : '3 Activity '}
                                        </span> */}
                                        {/* &nbsp; Includes: Transfer .Sightseeing .Hotel{" "} */}
                                    </p>

                                </div>
                            </div>
                            <div className=" col-lg-12">
                                <div className="private-transfer-outer d-flex">
                                    <div className="transfer-img" style={{ marginInlineEnd: 10 }}>
                                        <img src="img/small-car.svg" alt="" />
                                    </div>
                                    <div className="car-content">
                                        <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                             {value?.transport?.from}  {value?.transport?.to}
                                        </h3>
                                        <p className="sub-heading-2">
                                              {value?.transport?.vehicle}

                                        </p>
                                    </div>
                                </div>
                                <div className="transfer-outer d-flex">
                                    <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                                        <img width={"120"} src="/img/car.svg" alt="" />
                                    </div>
                                    <div className="car-content">
                                        <h3 className="heading" style={{ fontSize: 14 }}>
                                            {/* 3 Seater | AC | 2 Luggage Bags */}
                                            {value?.transport?.description?.replace(/,/g, ' | ')}
                                        </h3>
                                        {/* <p className="sub-heading-2">
                                            Intercity Transfer, 3 Sightseeing Transfers Included
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 18 }} />
                            <div className="  col-lg-12">
                                <div className="private-transfer-outer d-flex">
                                    <div className="car-content">
                                        <h3 className="sub-heading-2" style={{ fontSize: 30 }}>
                                            {/* Transfer from {value?.transport?.from} to {value?.transport?.to} */}
                                            Itinerary
                                        </h3>
                                    </div>
                                </div>
                                <div className="transfer-outer d-flex">
                                    <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                                        <img src="/img/map.svg" alt="" />
                                    </div>
                                    <div className="car-content">
                                        {value?.activities?.map((activity, index) => (
                                            <div key={index} className="activity-item">
                                                {/* {activity} */}
                                                <div dangerouslySetInnerHTML={createMarkup(activity)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="day-plan-border" style={{ marginBlock: 18 }} />
                            {value?.hotel && (
                                (value.hotel.name || value.hotel.location || value.hotel.hotelImages?.length > 0) && (
                                    <div className="col-lg-4">
                                        <div className="private-transfer-outer d-flex">
                                            <div className="car-content">
                                                <h3 className="sub-heading-2" style={{ fontSize: 18 }}>
                                                    Check-in to Hotel in {value?.hotel?.location}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="transfer-outer d-flex">
                                            <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                                                {/* <img src="/img/check-in-hotel.svg" alt="" /> */}
                                                <img src={`${process.env.REACT_APP_URL || ''}${value?.hotel?.hotelImages[0]
                                                    }`} alt="Hotel Image" style={{ height: '100%', width: '150px', objectFit: 'cover' }} />
                                            </div>
                                            <div className="car-content">
                                                <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                    {value?.hotel?.name}
                                                </h3>
                                                <h3 className="heading" style={{ fontSize: 14 }}>
                                                    {value?.hotel?.location}
                                                </h3>
                                                {/* <h3
                                            className="heading"
                                            style={{ fontSize: 14, marginBlock: 5 }}
                                        >
                                            9.1 KM Drive to Har Ki Puri
                                        </h3> */}
                                                <h3
                                                    className="heading"
                                                    style={{ fontSize: 14, color: "#1B1E28" }}
                                                >
                                                    {formattedDatehotel(value?.date)}
                                                </h3>
                                            </div>
                                        </div>

                                    </div>
                                )
                            )}
                            {value?.hotel && (
                                (value.hotel.roomType || value.hotel.meal.some(meal => meal)) && (
                                    <div className="col-lg-4">
                                        <div className="private-transfer-outer d-flex"></div>
                                        <div className="transfer-outer change-room-outer d-flex">
                                            <div className="car-content">
                                                <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                    {value?.hotel?.roomType}
                                                </h3>
                                                <h3 className="heading d-block" style={{ fontSize: 14 }}>
                                                    Meal Plan: {value?.hotel?.meal.map((meal, index) => (
                                                        <span key={index} className="activity-item">
                                                            {meal}
                                                        </span>
                                                    ))}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                            {value?.hotel && (
                                (value.hotel.roomType || value.hotel.meal.some(meal => meal)) && (
                                    <div className=" col-lg-4">
                                        <div className="private-transfer-outer d-flex"></div>
                                        <div
                                            className="transfer-outer change-room-outer d-flex"
                                            style={{ background: "rgba(36, 186, 236, 0.2)" }}
                                        >
                                            <div className="car-content">
                                                <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                    Your {value?.location} Destination Guide
                                                </h3>
                                                <h3 className="heading" style={{ fontSize: 14 }}>
                                                    Pack your bags for a memorable road trip to <br /> {value?.location}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                            {value?.hotel && (
                                (value.hotel.roomType || value.hotel.meal.some(meal => meal)) && (
                                    <div className="day-plan-border" style={{ marginBlock: 18 }} />
                                )
                            )}
                            <h3
                                className="sub-heading-2"
                                style={{ fontSize: 18, marginBlock: 15 }}
                            >
                                Summary
                            </h3>
                            {pkg?.schedule?.length > 0 ? (
                                <div className="">
                                    <div className="summary-outer">
                                        {pkg?.schedule?.map((day, index) => (
                                            <div key={index} className="summary-details d-flex" style={{ alignItems: "baseline" }}>
                                                {/* <div className="transfer-img" style={{ marginInlineEnd: 10 }}>
                                                    <img src="img/small-car-2.svg" alt="" />
                                                </div> */}
                                                <div className="car-content">
                                                    <h3 className="sub-heading-2" style={{ fontSize: 14, display: "contents" }}>
                                                        {/* Day {day?.day} */}

                                                    </h3>
                                                </div>
                                                <table>
                                                    <tbody>
                                                        <tr style={{ backgroundColor: "#FFEADF" }}>
                                                            <th colSpan={3} className="package-table-heading" style={{ padding: "10px 15px" }}>
                                                                {day?.location} {/* Location from the dynamic data */}
                                                            </th>
                                                        </tr>
                                                        <tr className='table-day-description'>
                                                            <td
                                                                style={{
                                                                    padding: 10,
                                                                    color: "#595959",
                                                                    backgroundColor: "#EDEDED"
                                                                }}
                                                                className='summary-table-day'
                                                            >
                                                                Day {day.day} | &nbsp;
                                                                <span>{formattedDate(day.date)}</span> {/* Display date */}
                                                            </td>
                                                            <td style={{ padding: 10 }}>
                                                                {/* <img src="/img/tracking-icon.svg" alt="" /> */}
                                                                {/* Sightseeing In {day.location} <br /> */}
                                                                <ul style={{ listStylePosition: 'inside' }}>
                                                                    {day.activities.map((activity, index) => (
                                                                        <div key={index}>
                                                                            {/* Safely render the activity HTML */}
                                                                            <div dangerouslySetInnerHTML={createMarkup(activity)} />
                                                                        </div>
                                                                    ))}

                                                                </ul>
                                                                {day.hotel?.name && day.hotel?.roomType && (
                                                                    <span>
                                                                        {" "}
                                                                        <img src="/img/building-icon.svg" alt="" /> Check in {day.hotel.name}  {day.hotel.roomType}
                                                                        <br />
                                                                    </span>
                                                                )}
                                                                {/* <span>

                                                                    <img src="/img/car-icon.svg" alt="" /> Private {day.transport.type} - {day.transport.vehicle}-{day.transport.description}
                                                                    in & around   {day.location}
                                                                </span><br />
                                                                <span>
                                                                    <img src="/img/spoons-icon.svg" alt="" />  Day Meals:   <ul style={{ listStylePosition: 'inside' }}>
                                                                        {day.hotel.meal.length > 0 ? (
                                                                            day.hotel.meal.map((meal, index) => (
                                                                                <li key={index}>{meal}</li>
                                                                            ))
                                                                        ) : (
                                                                            <li>No meals available</li>
                                                                        )}
                                                                    </ul>
                                                                </span> */}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p>No schedule available.</p>
                            )}
                            <div className="day-plan-border" style={{ marginBlock: 15 }} />
                        </div>
                        <div className="book-now-outer">
                            <div className="book-now-inner d-flex">
                                <div className="book-content">
                                    <h3 className="heading" style={{ fontSize: 14 }}>
                                        {/* {pkg?.price} */}
                                        <del>₹{pkg?.price}</del>
                                    </h3>
                                    <h4
                                        className="sub-heading-2"
                                        style={{ fontSize: 20, marginBlock: 8 }}
                                    >
                                        {pkg?.discountPrice}
                                    </h4>
                                    <h5 className="heading" style={{ fontSize: 14 }}>
                                        Per person
                                    </h5>
                                </div>
                                <div className="book-now-btn  ">
                                    {/* <Link  to ={`/booking/${id}`}>BOOK NOW</Link> */}
                                    <button onClick={handleBookNowClick} className="btn ">
                                        BOOK NOW
                                    </button>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer component */}
            <Footer />
        </>
    );
}
export default PackageDetails;