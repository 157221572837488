import React, { useState, useEffect } from 'react';
import useAsync from '../../hooks/useAsync';
import { useParams } from 'react-router-dom';
import Footer from './Common/footer';
import BookingServices from '../../services/BookingServies';
import moment from "moment";

function ViewBooking() {
    const { id } = useParams(); // Get the package ID from the URL
    const [booking, setBooking] = useState(null);
    const [value, setValue] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null); // To store the selected date
    const [scheduleData, setScheduleData] = useState(null);


    useEffect(() => {
        const fetchPackage = async () => {
            try {
                const response = await BookingServices.getMyBookingById(id);
                console.log("response", response); // Log the entire response
                setBooking(response.data); // Set pkg to the appropriate part of the response
                setValue(response?.data?.package?.schedule[0])
            } catch (err) {
                setError('Error loading package details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPackage();
    }, [id]);
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };
    const handleDateClick = (date, index) => {
        setActiveIndex(index); // Set the clicked date as active
        setValue(date);        // Update the selected value
    };
    const generateDates = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dates = [];

        while (start <= end) {
            dates.push(new Date(start));
            start.setDate(start.getDate() + 1); // Move to the next day
        }

        return dates;
    };
    const dates = generateDates(booking?.startDate, booking?.endDate);
    const formattedDatehotel = (inputDate) => {
        if (!inputDate) return "Date not available"; // Handle undefined dates

        const dateObj = new Date(inputDate);
        const nextDateObj = new Date(dateObj);
        nextDateObj.setDate(dateObj.getDate() + 1); // Calculate the next date

        const formattedCurrentDate = dateObj.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });

        const formattedNextDate = nextDateObj.toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });

        return `${formattedCurrentDate} - ${formattedNextDate}`;
    };
    const formatDay = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOfWeek = daysOfWeek[date.getDay()];

        return `${dayOfWeek}`;
    };
    const onDownloadInvoice = async (bookingId) => {
        try {
            // Make API call to get the invoice URL
            const response = await BookingServices.downloadInvoice(bookingId);
            if (response.status === 200 || response.url) {
                // Open the invoice URL in a new tab
                // window.open(`${process.env.REACT_APP_URL}${response.url}`, '_blank');
                window.open(`http://143.244.135.240:3002/${response.url}`, '_blank');
            } else {
                alert("Unable to download invoice. Please try again later.");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
            alert("An error occurred while downloading the invoice.");
        }
    };
    const onDownloadCancelInvoice = async (bookingId) => {
        try {
            // Make API call to get the invoice URL
            const response = await BookingServices.downloadCancelInvoice(bookingId);
            if (response.status === 200 || response.url) {
                // Open the invoice URL in a new tab
                window.open(`http://143.244.135.240:3002/${response.url}`, '_blank');
            } else {
                alert("Unable to download invoice. Please try again later.");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
            alert("An error occurred while downloading the invoice.");
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };
    const formatDateMonth = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options); // "22 Oct, 2024"
    };
    const formattedDate = (inputDate) => {
        const date = new Date(inputDate);
        const options = {
            month: 'short',
            day: 'numeric',
            weekday: 'short'
        };

        // Get the formatted date as "Mon, Oct 14"
        const dateString = date.toLocaleDateString('en-US', options);

        // Rearrange the date to be in the desired format: "Oct 14,Mon"
        const [weekday, monthDay] = dateString.split(', '); // Split by the comma
        return `${monthDay},${weekday}`; // Rearrange and remove space
    };
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <section className="notification-section order-section">
                <div className="container">
                    <div className="tour-package-outer">
                        <h3 className="banner-heading">View Booking</h3>
                    </div>
                </div>
            </section>
            <section className="all-package-section">
                <div className="container">
                    <div className="heading-outer d-flex" style={{ marginBlockStart: 20 }}>
                        <div className="main-heading-outer">
                            <h3 className="main-heading">View Booking</h3>
                        </div>
                        <div className="view-all-btn">
                            <a href="#"> </a>
                        </div>
                    </div>
                    <div className="notification-inner">
                        <div className="row">
                            <div className="col-lg-4">
                                <h5 className="heading">{booking?.packageName}</h5>
                                <p className="sub-heading" style={{ marginBottom: 10 }}>
                                    {booking?.startLocation}  {booking?.endLocation}
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <div className="booking-date-outer d-flex">
                                    <div className="booking-outer-2">
                                        <h5 className="heading" style={{ fontSize: 16 }}>
                                            {formatDateMonth(booking?.startDate)}
                                        </h5>
                                        <p className="sub-heading" style={{ marginBottom: 10 }}>
                                            {formatDay(booking?.startDate)}
                                        </p>
                                    </div>
                                    <div className="booking-outer-3">
                                        <a
                                            href="#"
                                            className=" package-day"
                                            style={{
                                                background: "rgba(108, 112, 114, 0.15)",
                                                color: "#6C7072"
                                            }}
                                        >
                                            {booking?.duration}
                                        </a>
                                    </div>
                                    <div className="booking-outer-2">
                                        <h5 className="heading" style={{ fontSize: 16 }}>
                                            {formatDateMonth(booking?.endDate)}
                                        </h5>
                                        <p className="sub-heading" style={{ marginBottom: 10 }}>
                                            {formatDay(booking.endDate)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="booking-date-outer">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading booking-heading"
                                            style={{ fontSize: 16, textAlign: "end" }}
                                        >
                                            {/* 2 Travellers: <span>2 Adults / From New Delhi</span> */}
                                            {booking?.passengers?.length} Travellers:
                                            <span>
                                                {booking.passengers.filter(p => p.age >= 15).length} Adults,
                                                {booking.numberOfChildren} Child
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 15 }} />
                            <div className="col-lg-6">
                                <div className="booking-date-outer d-flex">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginBottom: 10 }}
                                        >
                                            Price Breakup
                                        </h5>
                                        <div className="view-booking-outer d-flex  ">
                                            <h5 className="heading" style={{ fontSize: 16 }}>
                                                Price
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockStart: 29 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.packagePrice}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Member Quantiy
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.quantity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Tax
                                    </h5>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.tax}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Registration Fees
                                    </h5>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.registrationFees || '0'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 10 }} />
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer d-flex"
                                    style={{ marginBlockEnd: 10 }}
                                >
                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                        Total Amount 
                                    </h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="booking-date-outer "
                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                >
                                    <div className="booking-outer-2">
                                        <div className="view-booking-outer   ">
                                            <p className="sub-heading" style={{ textAlign: "right" }}>
                                                {booking?.totalAmount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 10 }} />
                            <div className="col-lg-6">
                                <div className="booking-date-outer">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginBottom: 10 }}
                                        >
                                            Passenger Details
                                        </h5>
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr style={{ backgroundColor: "#D1D1D1" }}>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        S. NO
                                                    </th>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        NAME
                                                    </th>

                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        AGE
                                                    </th>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        GENDER
                                                    </th>
                                                    <th
                                                        className="package-table-heading"
                                                        style={{ padding: "10px 15px" }}
                                                    >
                                                        CITY
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {booking?.passengers?.map((passenger, index) => (
                                                    <tr key={index}>
                                                        <td style={{ padding: 10 }}>{index + 1}.</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.name}</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.age}</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.gender}</td>
                                                        <td style={{ padding: 10, color: "#595959" }}>{passenger.city}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginTop: 10 }}
                                        >
                                            Children Details
                                        </h5>
                                        <p><strong>Number Of Children :</strong> {booking?.numberOfChildren || 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="booking-date-outer">
                                    <div className="booking-outer-2">
                                        <h5
                                            className="heading"
                                            style={{ fontSize: 16, marginBottom: 10 }}
                                        >
                                            Contact Information
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Name
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Email Id
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Phone Number
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.mobileNo}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        State
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.state}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        City
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.city}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer"
                                                    style={{ marginBlockEnd: 10 }}
                                                >
                                                    <h5 className="heading" style={{ fontSize: 16 }}>
                                                        Address
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    className="booking-date-outer "
                                                    style={{ justifyContent: "end", marginBlockEnd: 10 }}
                                                >
                                                    <div className="booking-outer-2">
                                                        <div className="view-booking-outer   ">
                                                            <p
                                                                className="sub-heading"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {booking?.contact?.address}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 10 }} />
                            <div className="row g-4">
                                {booking?.package?.images && booking?.package?.images.length > 0 ? (
                                    <>
                                        <div className="col-md-4">
                                            <div className="package-details-img-outer">
                                                <img
                                                    src={`${process.env.REACT_APP_URL || ''}${booking?.package?.images[0]}`}
                                                    alt="Package Main"
                                                />
                                            </div>
                                        </div>
                                        {booking?.package?.images.slice(1).map((image, index) => (
                                            <div key={index} className="col-md-4">
                                                <div className="package-details-img-outer">
                                                    <img
                                                        src={`${process.env.REACT_APP_URL || ''}${image}`}
                                                        alt={`Package image ${index + 1}`}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div>No images available</div>
                                )}
                            </div>

                            <div className="details-heading-outer">
                                <div className="heading-outer d-flex">
                                    <div className="main-heading-outer">
                                        <h3
                                            className="main-heading package-main-heading"
                                            style={{ fontSize: 28, marginBottom: 10 }}
                                        >
                                            {booking?.packageName} &nbsp;

                                            <span>
                                                <a href="#" className=" package-day package-day-1 ">
                                                    {booking?.duration}
                                                </a>
                                            </span>
                                        </h3>
                                        <p className="sub-heading" style={{ marginBottom: 10 }}>
                                            {/* 1N Punjab 1N Manali 1N Shimla{" "} */}
                                            {booking?.startLocation}  {booking?.endLocation}
                                        </p>
                                    </div>
                                    <div className="view-all-btn">
                                        <div className="modify-outer">
                                            <p className="sub-heading-2">
                                                {booking?.startLocation} &nbsp; - &nbsp;
                                                {new Date(booking?.startDate).toLocaleDateString('en-GB', {
                                                    day: 'numeric',
                                                    month: 'short'
                                                })} -
                                                {new Date(booking?.endDate).toLocaleDateString('en-GB', {
                                                    day: 'numeric',
                                                    month: 'short'
                                                })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-lg-12">
                                    <div className="calendar-inner">
                                        <div className="calendar-outer d-flex" style={{ marginBottom: 12, flexWrap: 'wrap' }}>
                                            <div className="day-plan" style={{ background: "#6d528d", color: "#fff" }}>
                                                <p className="sub-heading-2 text-white">Day Plan</p>
                                            </div>
                                        </div>

                                        {/* <div className="calendar-outer d-flex" style={{ flexWrap: 'wrap' }}>
                                        {pkg?.schedule.map((date, index) => (
                                            <div key={index} className="day-plan" onClick={() => handleDateClick(date)} style={{ cursor: 'pointer' }}>
                                                <p className="sub-heading-2">
                                                    {date?.date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase()} <br />
                                                    {date.getDate()}
                                                </p>
                                            </div>
                                        ))}
                                    </div> */}
                                        <div className="calendar-outer d-flex" style={{ flexWrap: 'wrap' }}>
                                            {booking?.package?.schedule.map((date, index) => {

                                                const dateObj = new Date(date.date);
                                                const dayName = dateObj.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();

                                                const dayNumber = dateObj.getDate();
                                                return (
                                                    // <div key={index} className="day-plan" onClick={() => setValue(date)} style={{ cursor: 'pointer' }}>
                                                    <div
                                                        key={index}
                                                        className={`day-plan ${index === activeIndex ? 'active' : ''}`}  // Add active class if the index matches
                                                        onClick={() => handleDateClick(date, index)}
                                                        style={{ cursor: 'pointer', border: index === activeIndex ? '2px solid #0085ff' : 'none' }}  // Apply border to the active item
                                                    >
                                                        <p className="sub-heading-2">
                                                            {dayName} <br /> {dayNumber}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>



                                        {/* Display the relevant schedule for the selected date */}
                                        {
                                            scheduleData && (
                                                <div className="day-plan-2">
                                                    <p className="sub-heading">
                                                        <a href="#" className="package-day" style={{ backgroundColor: '#FF7029', color: '#fff' }}>
                                                            Day {dates.indexOf(selectedDate) + 1}
                                                        </a>
                                                        {/* Includes: {scheduleData.inclusions || 'Transfer . Sightseeing . Hotel'} */}
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="day-plan-2 justify-content-start">
                                        <p className="sub-heading">
                                            {" "}
                                            <span
                                                className=" package-day"
                                                style={{ backgroundColor: "#FF7029", color: "#fff" }}
                                            >
                                                Day {value?.day}
                                            </span>
                                            &nbsp;
                                            {/* <span
                                            className=" package-day"
                                            style={{ backgroundColor: "rgba(36, 186, 236, 0.2)", color: "#595959" }}
                                        >
                                            {value?.activities && value?.activities.length > 0
                                                ? `${value?.activities.length} Activities`
                                                : '3 Activity '}
                                        </span> */}
                                            {/* &nbsp; Includes: Transfer .Sightseeing .Hotel{" "} */}
                                        </p>

                                    </div>
                                </div>
                                <div className=" col-lg-12">
                                    <div className="private-transfer-outer d-flex">
                                        <div className="transfer-img" style={{ marginInlineEnd: 10 }}>
                                            <img src="img/small-car.svg" alt="" />
                                        </div>
                                        <div className="car-content">
                                            <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                {value?.transport?.from}  {value?.transport?.to}
                                            </h3>
                                            <p className="sub-heading-2">
                                               {value?.transport?.vehicle}

                                            </p>
                                        </div>
                                    </div>
                                    <div className="transfer-outer d-flex">
                                        <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                                            <img width={"120"} src="/img/car.svg" alt="" />
                                        </div>
                                        <div className="car-content">
                                            <h3 className="heading" style={{ fontSize: 14 }}>
                                                {/* 3 Seater | AC | 2 Luggage Bags */}
                                                {value?.transport?.description?.replace(/,/g, ' | ')}
                                            </h3>
                                            {/* <p className="sub-heading-2">
                                            Intercity Transfer, 3 Sightseeing Transfers Included
                                        </p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="day-plan-border" style={{ marginBlock: 18 }} />
                                <div className="  col-lg-12">
                                    <div className="private-transfer-outer d-flex">
                                        <div className="car-content">
                                            <h3 className="sub-heading-2" style={{ fontSize: 30 }}>
                                            Itinerary
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="transfer-outer d-flex">
                                        <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                                            <img src="/img/map.svg" alt="" />
                                        </div>
                                        <div className="car-content">
                                            {value?.activities?.map((activity, index) => (
                                                <div key={index} className="activity-item">
                                                    {/* {activity} */}
                                                    <div dangerouslySetInnerHTML={createMarkup(activity)} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="day-plan-border" style={{ marginBlock: 18 }} />
                                {value?.hotel && (
                                    (value.hotel.name || value.hotel.location || value.hotel.hotelImages?.length > 0) && (
                                        <div className="col-lg-4">
                                            <div className="private-transfer-outer d-flex">
                                                <div className="car-content">
                                                    <h3 className="sub-heading-2" style={{ fontSize: 18 }}>
                                                        Check-in to Hotel in {value?.hotel?.location}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="transfer-outer d-flex">
                                                <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                                                    {/* <img src="/img/check-in-hotel.svg" alt="" /> */}
                                                    <img src={`${process.env.REACT_APP_URL || ''}${value?.hotel?.hotelImages[0]
                                                        }`} alt="Hotel Image" style={{ height: '100%', width: '150px', objectFit: 'cover' }} />
                                                </div>
                                                <div className="car-content">
                                                    <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                        {value?.hotel?.name}
                                                    </h3>
                                                    <h3 className="heading" style={{ fontSize: 14 }}>
                                                        {value?.hotel?.location}
                                                    </h3>
                                                    {/* <h3
                                            className="heading"
                                            style={{ fontSize: 14, marginBlock: 5 }}
                                        >
                                            9.1 KM Drive to Har Ki Puri
                                        </h3> */}
                                                    <h3
                                                        className="heading"
                                                        style={{ fontSize: 14, color: "#1B1E28" }}
                                                    >
                                                        {formattedDatehotel(value?.date)}
                                                    </h3>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                )}
                                {value?.hotel && (
                                    (value.hotel.roomType || value.hotel.meal.some(meal => meal)) && (
                                        <div className="col-lg-4">
                                            <div className="private-transfer-outer d-flex"></div>
                                            <div className="transfer-outer change-room-outer d-flex">
                                                <div className="car-content">
                                                    <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                        {value?.hotel?.roomType}
                                                    </h3>
                                                    <h3 className="heading d-block" style={{ fontSize: 14 }}>
                                                        Meal Plan: {value?.hotel?.meal.map((meal, index) => (
                                                            <span key={index} className="activity-item">
                                                                {meal}
                                                            </span>
                                                        ))}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                                {value?.hotel && (
                                    (value.hotel.roomType || value.hotel.meal.some(meal => meal)) && (
                                        <div className=" col-lg-4">
                                            <div className="private-transfer-outer d-flex"></div>
                                            <div
                                                className="transfer-outer change-room-outer d-flex"
                                                style={{ background: "rgba(36, 186, 236, 0.2)" }}
                                            >
                                                <div className="car-content">
                                                    <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                                        Your {value?.location} Destination Guide
                                                    </h3>
                                                    <h3 className="heading" style={{ fontSize: 14 }}>
                                                        Pack your bags for a memorable road trip to <br /> {value?.location}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                                {value?.hotel && (
                                    (value.hotel.roomType || value.hotel.meal.some(meal => meal)) && (
                                        <div className="day-plan-border" style={{ marginBlock: 18 }} />
                                    )
                                )}
                                <h3
                                    className="sub-heading-2"
                                    style={{ fontSize: 18, marginBlock: 15 }}
                                >
                                    Summary
                                </h3>
                                {booking?.package?.schedule?.length > 0 ? (
                                    <div className="">
                                        <div className="summary-outer">
                                            {booking?.package?.schedule?.map((day, index) => (
                                                <div key={index} className="summary-details d-flex" style={{ alignItems: "baseline" }}>
                                                    {/* <div className="transfer-img" style={{ marginInlineEnd: 10 }}>
                                                    <img src="img/small-car-2.svg" alt="" />
                                                </div> */}
                                                    <div className="car-content">
                                                        <h3 className="sub-heading-2" style={{ fontSize: 14, display: "contents" }}>
                                                            {/* Day {day?.day} */}

                                                        </h3>
                                                    </div>
                                                    <table>
                                                        <tbody>
                                                            <tr style={{ backgroundColor: "#FFEADF" }}>
                                                                <th colSpan={3} className="package-table-heading" style={{ padding: "10px 15px" }}>
                                                                    {day?.location} {/* Location from the dynamic data */}
                                                                </th>
                                                            </tr>
                                                            <tr className='table-day-description'>
                                                                <td
                                                                    style={{
                                                                        padding: 10,
                                                                        color: "#595959",
                                                                        backgroundColor: "#EDEDED"
                                                                    }}
                                                                    className='summary-table-day'
                                                                >
                                                                    Day {day.day} <br />
                                                                    <span>{formattedDate(day.date)}</span> {/* Display date */}
                                                                </td>
                                                                <td style={{ padding: 10 }}>
                                                                    {/* <img src="/img/tracking-icon.svg" alt="" /> Sightseeing In {day.location} <br /> */}
                                                                    <ul style={{ listStylePosition: 'inside' }}>
                                                                        {day.activities.map((activity, index) => (
                                                                            <div key={index}>
                                                                                {/* Safely render the activity HTML */}
                                                                                <div dangerouslySetInnerHTML={createMarkup(activity)} />
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                    {/* <span>
                                                                        {" "}
                                                                        <img src="/img/building-icon.svg" alt="" /> Check in {day.hotel.name}  {day.hotel.roomType}
                                                                    </span><br />
                                                                    <span>

                                                                        <img src="/img/car-icon.svg" alt="" /> Private {day.transport.type} - {day.transport.vehicle}-{day.transport.description}
                                                                        in & around   {day.location}
                                                                    </span><br />
                                                                    <span>

                                                                        <img src="/img/spoons-icon.svg" alt="" />  Day Meals:   <ul style={{ listStylePosition: 'inside' }}>
                                                                            {day.hotel.meal.length > 0 ? (
                                                                                day.hotel.meal.map((meal, index) => (
                                                                                    <li key={index}>{meal}</li>
                                                                                ))
                                                                            ) : (
                                                                                <li>No meals available</li>
                                                                            )}
                                                                        </ul>
                                                                    </span> */}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <p>No schedule available.</p>
                                )}
                                <div className="day-plan-border" style={{ marginBlock: 15 }} />
                            </div>
                            <div className="day-plan-border" style={{ marginBlock: 15 }} />

                            <div
                                className="change-btn download-btn d-flex"
                                style={{ marginTop: 12 }}
                            >
                                {/* <a href="#" onClick={() => onDownloadInvoice(booking._id)}  style={{ backgroundColor: "#FF7029" }} >
                                    View Invoice
                                </a> */}
                                {/* <a href="#" onClick={() => onDownloadInvoice(booking._id)}  style={{ backgroundColor: "#FF7029" }}> View Invoice </a> */}
                                <a
                                    href="#"
                                    onClick={() =>
                                        booking.status === "Cancelled"
                                            ? onDownloadCancelInvoice(booking._id)
                                            : onDownloadInvoice(booking._id)
                                    }
                                    style={{ backgroundColor: "#FF7029" }}
                                >
                                    View Invoice
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer component */}
            <Footer />
        </>

    );
}

export default ViewBooking;
