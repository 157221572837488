import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import useAsync from '../hooks/useAsync'
import UserServices from '../services/UserServices'
import ViewUser from './View Model/viewVendor';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import UserStatus from './TogelButton/TogleButtonForStatus';
import Pagetitle from './PageTitle';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
Modal.setAppElement('#root');

function VendorManager() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllVendor);
  // console.log(data);

  const count = data?.users?.length;
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleViewDetails = (vendor) => {
    setSelectedUser(vendor);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };


  const usersPerPage = 10;
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const filteredUsers = data?.data?.filter(vendor => {
    return (
      vendor?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor?.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      vendor?.mobileNo.toString().toLowerCase().includes(searchQuery.toLowerCase()) 
    );
  });

  const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);

  const generateExcel = () => {
    const filteredData = filteredUsers?.map(user => ({
      name: user?.name,
      image: process.env.REACT_APP_URL + user?.image,
      mobileNo: user?.mobileNo,
      address : user?.address,
      registerdate: formatDate(user?.createdAt),
      status: user?.status
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    XLSX.writeFile(wb, 'user_data.xlsx');
  };

  useEffect(() => {
    if (data?.users) {
      run(UserServices.getAllVendor);
    }
  }, [data]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="right_col" role="main">
      <Pagetitle></Pagetitle>
      {/* <div className="title-box">
      
        <h2>User  <span className="badge bg-warning">{count}</span></h2>
      </div> */}
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input
              type="search"
              name="search"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="search-btn"><FontAwesomeIcon icon={faSearch} /></button>
            {/* <select className="dropdown" >
              <option value="" disabled>Select City</option>
              <option value="New York">New York</option>
              <option value="Los Angeles">Los Angeles</option>
              <option value="Chicago">Chicago</option>
              <option value="Houston">Houston</option>
              <option value="Miami">Miami</option>
             
            </select> */}

          </div>
          <div className="sub-title-box-right">
            {/* <Link className="Add-new-btn site-btn-green me-3" to="/user-add">
              <img src="img/plus-circle.svg" alt='' /><span className='ms-2'>Add User</span>
            </Link> */}
            <button className="excel-btn site-btn-green" onClick={generateExcel}><img src="img/excel.svg" alt='' /><span className='ms-2'>Download</span></button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Address</th>
                <th>Category Services</th>
                <th>Reg. Date</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((vendor, index) => (
                <tr key={vendor?._id}>
                  <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                  <td> <img src={vendor?.image ? `${process.env.REACT_APP_URL}${vendor.image}` : 'img/profile-img.png'} alt="" style={{ height: '50px', width: '50px', objectFit: 'contain' }} /></td>
                  <td>{vendor?.name}</td>
                  <td>{vendor?.mobileNo}</td>
                  <td>{vendor?.address}</td>
                  <td>{vendor?.categoryService || 'N/A'}</td>
                  <td>{formatDate(vendor?.createdAt)}</td>
                  <td className="status-toggle">
                    {/* <UserStatus vendor={vendor} page={vendor} onSuccess={run} /> */}
                    <UserStatus data={vendor} page="vendor" onSuccess={run}/>
                    </td>
                    
                  <td>
                    <button className="view-details-btn" onClick={() => handleViewDetails(vendor)}><FontAwesomeIcon icon={faEllipsisV} /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil((filteredUsers?.length || 0) / usersPerPage)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage - 1}
            disableInitialCallback={true}
            pageLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            disabledClassName={'disabled'}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ViewUser vendor={selectedUser} closeModal={closeModal} />
      </Modal>
    </div>
  )
}

export default VendorManager;
