import React, { useState } from 'react';

import ProductServices from '../../services/ProductServices';
import PetServices from '../../services/PetServices';
import EducationServices from '../../services/EducationServices';
import UserServices from '../../services/UserServices';
import PackageServices from '../../services/PackageServices';

const TogalButtonForStatus = ({ data, page ,onSuccess}) => {
  const [isChecked, setIsChecked] = useState(data?.status === 'Active');

  // Handle checkbox toggle
  const handleToggle = async () => {
    const newStatus = !isChecked ? 'Active' : 'Inactive'; 
    setIsChecked(!isChecked);
    

    if (page === 'Product') {
      const res = await ProductServices.updateProduct(data._id, {
        status: newStatus
      });
      onSuccess();
    }else if (page === 'Animal') {
      const res = await PetServices.updateSellAnimal(data._id, {
        status: newStatus
      });
      onSuccess();
    }else if (page === 'package') {
      const res = await PackageServices.updatePackage(data._id, {
        status: newStatus
      });
      onSuccess();
    }
    else if (page === 'Pet') {
      const res = await PetServices.updateAppPolicy(data._id, {
        status: newStatus
      });
      onSuccess();
    }else if (page === 'category') {
      const res = await ProductServices.UpdateCategory(data._id, {
        status: newStatus
      });
      onSuccess();
    }
    else if (page === 'user') {
      const res = await UserServices.updateUser(data._id, {
        status: newStatus
      });
      onSuccess();
    }
    else if (page === 'vendor') {
      const res = await UserServices.updateVendor(data._id, {
        status: newStatus
      });
      onSuccess();
    }
    else if (page === 'education') {
      const res = await EducationServices.updateEducation(data._id, {
        status: newStatus
      });
      onSuccess();
    }
  
  };

  return (
   
      <div className="check-box">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
      </div>
    
  );
};

export default TogalButtonForStatus;
