import React, { useState, useEffect } from 'react';
import PackageServices from '../../services/PackageServices';
import DestinationServices from '../../services/DestinationServices';
import ReactQuill from 'react-quill';

function EditPackage({ pkg, closeModal, onSuccess }) {
    // console.log(">>", pkg)
    const [formValues, setFormValues] = useState({
        destination: '',
        packageName: '',
        numberOfPackage:'',
        price: '',
        discountPrice: '',
        tax: '',
        cancelPrice: '',
        registrationPrice:'',
        datechangePrice: '',
        duration: '',
        startDate: '',
        endDate: '',
        cancelDate: '',
        changeDate: '',
        startLocation: '',
        endLocation: '',
        startingPoints: '',
        images: [],
        packageInclusions: '',
        packageExclusions: '',
        policy: ''
    });

    const [existingImages, setExistingImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [destinations, setDestinations] = useState([]);

    // Fetch destinations on component mount
    useEffect(() => {
        const fetchDestinations = async () => {
            try {
                const res = await DestinationServices.getDestination();
                if (res.status) {
                    setDestinations(res.data);
                } else {
                    alert('Failed to fetch destinations');
                }
            } catch (error) {
                console.error('Failed to fetch destinations', error);
                alert('Failed to fetch destinations');
            }
        };

        fetchDestinations();
    }, []);

    // Populate form with selected package data
    useEffect(() => {
        if (pkg) {
            setFormValues({
                destination: pkg?.destination?._id || '',
                packageName: pkg?.packageName || '',
                numberOfPackage: pkg?.numberOfPackage || '',
                price: pkg?.price || '',
                discountPrice: pkg?.discountPrice || '',
                tax: pkg?.tax || '',
                cancelPrice: pkg?.cancelPrice || '',
                registrationPrice: pkg?.registrationPrice || '',
                datechangePrice: pkg?.datechangePrice || '',
                duration: pkg?.duration || '',
                startDate: pkg?.startDate ? pkg.startDate.split('T')[0] : '',
                endDate: pkg?.endDate ? pkg.endDate.split('T')[0] : '',
                cancelDate: pkg?.cancelDate ? pkg.cancelDate.split('T')[0] : '',
                changeDate: pkg?.changeDate ? pkg.changeDate.split('T')[0] : '',
                startLocation: pkg?.startLocation || '',
                endLocation: pkg?.endLocation || '',
                startingPoints: pkg?.startingPoints || '',
                packageInclusions: pkg?.packageInclusions[0] || '',
                packageExclusions: pkg?.packageExclusions[0] || '',
                policy: pkg?.policy[0] || '',
                images: []
            });
            setExistingImages(pkg?.images || []);
            setPreviewImages([]);
        }
    }, [pkg]);
    // const calculateDuration = (startDate, endDate) => {
    //     if (startDate && endDate) {
    //         const start = new Date(startDate);
    //         const end = new Date(endDate);

    //         const timeDiff = Math.abs(end - start);
    //         const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    //         const nights = days - 1;
    //         return `${nights}N/${days}D`;
    //     }
    //     return '';
    // };

    const calculateDuration = (startDate, endDate) => {
        if (startDate && endDate) {
          const start = new Date(startDate);
          const end = new Date(endDate);
          
          // Ensure the time portion doesn't affect the calculation
          start.setHours(0, 0, 0, 0);
          end.setHours(0, 0, 0, 0);
      
          // Calculate the difference in milliseconds and convert to days
          const timeDiff = end - start;
          const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
      
          const nights = days - 1;
          return `${nights}N/${days}D`;
        }
        return '';
      };
      
    // Handle form field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
            // Update duration automatically if startDate or endDate changes
            duration:
                name === 'startDate' || name === 'endDate'
                    ? calculateDuration(
                        name === 'startDate' ? value : formValues.startDate,
                        name === 'endDate' ? value : formValues.endDate
                    )
                    : prevValues.duration
        }));
    };

    // Handle Quill editor changes for rich text fields
    const handleQuillChange = (name, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    // Handle file input for image uploads and previews
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFormValues({
            ...formValues,
            images: files,
        });

        // Generate preview for new images
        const previewFiles = files.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        });

        Promise.all(previewFiles).then((images) => {
            setPreviewImages(images);
        });
    };

    // Handle removing an existing image
    const handleRemoveExistingImage = (index) => {
        const updatedImages = existingImages.filter((_, i) => i !== index);
        setExistingImages(updatedImages);
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();

            // Append form values (excluding images)
            for (const key in formValues) {
                if (key !== 'images') {
                    formData.append(key, formValues[key]);
                }
            }

            // Append new images
            formValues.images.forEach((image) => {
                formData.append('images', image);
            });

            // Append existing images (that haven't been removed)
            existingImages.forEach((image) => {
                formData.append('existingImages', image);
            });

            // Update package via API
            const res = await PackageServices.updatePackage(pkg._id, formData);
            if (res.status === true) {
                alert('Package updated successfully');
                setPreviewImages([]);
                onSuccess();
                closeModal();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to update package', error);
            alert('Failed to update package');
        }
    };

    return (

        <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Day to Package</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="container-box-inner">
                            <div className="page-details">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {/* Destination field */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Destination</label>
                                                <select
                                                    name="destination"
                                                    className="form-control"
                                                    value={formValues.destination}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select a destination</option>
                                                    {destinations.map((dest) => (
                                                        <option key={dest._id} value={dest._id}>
                                                            {dest.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Package Name */}
                                        <div className="col-lg-4 col-md-6">
                                            <div className="input-field">
                                                <label>Package Name</label>
                                                <input
                                                    type="text"
                                                    name="packageName"
                                                    value={formValues.packageName}
                                                    onChange={handleInputChange}
                                                    placeholder="Package Name"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Start Location</label>
                                                <input
                                                    type="text"
                                                    name="startLocation"
                                                    value={formValues.startLocation}
                                                    onChange={handleInputChange}
                                                    placeholder="Start Location"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>End Location</label>
                                                <input
                                                    type="text"
                                                    name="endLocation"
                                                    value={formValues.endLocation}
                                                    onChange={handleInputChange}
                                                    placeholder="End Location"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Start Date</label>
                                                <input
                                                    type="date"
                                                    name="startDate"
                                                    value={formValues.startDate}
                                                    onChange={handleInputChange}
                                                    placeholder="Start Date"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>End Date</label>
                                                <input
                                                    type="date"
                                                    name="endDate"
                                                    value={formValues.endDate}
                                                    onChange={handleInputChange}
                                                    placeholder="End Date"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Cancel Date Limit</label>
                                                <input
                                                    type="date"
                                                    name="cancelDate"
                                                    value={formValues.cancelDate}
                                                    onChange={handleInputChange}
                                                    placeholder="Cancel Date"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Change Date Limit</label>
                                                <input
                                                    type="date"
                                                    name="changeDate"
                                                    value={formValues.changeDate}
                                                    onChange={handleInputChange}
                                                    placeholder="Change Date"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Duration (In Days)</label>
                                                <input
                                                    type="text"
                                                    name="duration"
                                                    value={formValues.duration}
                                                    onChange={handleInputChange}
                                                    placeholder="No. of Days"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Amount</label>
                                                <input
                                                    type="text"
                                                    name="price"
                                                    value={formValues.price}
                                                    onChange={handleInputChange}
                                                    placeholder="Amount"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Offer Amount</label>
                                                <input
                                                    type="text"
                                                    name="discountPrice"
                                                    value={formValues.discountPrice}
                                                    onChange={handleInputChange}
                                                    placeholder="Offer Amount"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Tax</label>
                                                <input
                                                    type="text"
                                                    name="tax"
                                                    required
                                                    value={formValues.tax}
                                                    onChange={handleInputChange}
                                                    placeholder="Tax in percentage"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Cancel Price</label>
                                                <input
                                                    type="text"
                                                    name="cancelPrice"
                                                    required
                                                    value={formValues.cancelPrice}
                                                    onChange={handleInputChange}
                                                    placeholder="Cancel Price in percentage"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Date Change Price</label>
                                                <input
                                                    type="text"
                                                    name="datechangePrice"
                                                    required
                                                    value={formValues.datechangePrice}
                                                    onChange={handleInputChange}
                                                    placeholder="Date Change Price Price in percentage"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Number Of Package*</label>
                                                <input
                                                    type="number"
                                                    name="numberOfPackage"
                                                    value={formValues.numberOfPackage}
                                                    onChange={handleInputChange}
                                                    placeholder="Number Of Package"
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="input-field">
                                                <label>Registration Price</label>
                                                <input
                                                    type="text"
                                                    name="registrationPrice"
                                                    required
                                                    value={formValues.registrationPrice}
                                                    onChange={handleInputChange}
                                                    placeholder="150"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        {/* package exclusion and policy */}
                                        <div className="col-lg-12">
                                            <div className="input-field">
                                                <label>Package Inclusion</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={formValues?.packageInclusions}
                                                    onChange={(value) => handleQuillChange('packageInclusions', value)}
                                                    placeholder="Package Inclusion"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input-field">
                                                <label>Booking Description</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={formValues?.packageExclusions}
                                                    onChange={(value) => handleQuillChange('packageExclusions', value)}
                                                    placeholder="Description"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input-field">
                                                <label>Package Policy</label>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={formValues?.policy}
                                                    onChange={(value) => handleQuillChange('policy', value)}
                                                    placeholder="Package Inclusion"
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        {/* package exclusion and policy */}
                                        <div className="col-sm-12">
                                            <div className="input-field">
                                                <label>Existing Images</label>
                                                <div className="image-preview">
                                                    {existingImages?.length > 0 ? (
                                                        existingImages.map((image, index) => (
                                                            <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                                                                <img
                                                                    src={`${process.env.REACT_APP_URL}${image}`} // Ensure base URL is added here
                                                                    alt={`existing ${index}`}
                                                                    style={{ maxWidth: '100px', margin: '5px' }}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleRemoveExistingImage(index)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '0',
                                                                        right: '0',
                                                                        backgroundColor: 'red',
                                                                        color: 'white',
                                                                        border: 'none',
                                                                        borderRadius: '50%',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No existing images</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-12">
                                            <div className="input-field">
                                                <label>Upload New Images</label>
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={handleFileChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="image-preview">
                                                {previewImages?.length > 0 ? (
                                                    previewImages.map((image, index) => (
                                                        <img key={index} src={image} alt={`preview ${index}`} style={{ maxWidth: '100px', margin: '5px' }} />
                                                    ))
                                                ) : (
                                                    <p>No new images selected</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="btn btn-primary">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default EditPackage;
