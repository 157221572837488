import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import PackageServices from '../../services/PackageServices';
import DestinationServices from '../../services/DestinationServices';

import ReactQuill from 'react-quill';
import Pagetitle from '../PageTitle';

function AddPackage() {
  const [formValues, setFormValues] = useState({
    destination: '',
    numberOfPackage: '',
    packageName: '',
    price: '',
    discountPrice: '',
    tax:'',
    datechangePrice:'',
    cancelPrice:'',
    registrationPrice:'',
    duration: '',
    startDate: '',
    endDate: '',
    cancelDate: '',
    changeDate: '',
    startLocation: '',
    endLocation: '',
    startingPoints: '',
    images: [],
    packageInclusions: '',
    packageExclusions: '',
    policy: '',
    policy: ''
  });
  const navigate =  useNavigate();
  const [previewImages, setPreviewImages] = useState([]);
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const res = await DestinationServices.getDestination();
        if (res.status) {
          setDestinations(res.data);
        } else {
          alert('Failed to fetch destinations');
        }
      } catch (error) {
        console.error('Failed to fetch destinations', error);
        alert('Failed to fetch destinations');
      }
    };

    fetchDestinations();
  }, []);
    // Function to calculate the duration between start and end dates
    // const calculateDuration = (startDate, endDate) => {
    //   if (startDate && endDate) {
    //     const start = new Date(startDate);
    //     const end = new Date(endDate);
  
    //     const timeDiff = Math.abs(end - start);
    //     const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  
    //     const nights = days - 1;
    //     return `${nights}N/${days}D`;
    //   }
    //   return '';
    // };
    const calculateDuration = (startDate, endDate) => {
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Ensure the time portion doesn't affect the calculation
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
    
        // Calculate the difference in milliseconds and convert to days
        const timeDiff = end - start;
        const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
    
        const nights = days - 1;
        return `${nights}N/${days}D`;
      }
      return '';
    };
    
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
       // Update duration automatically if startDate or endDate changes
       duration:
       name === 'startDate' || name === 'endDate'
         ? calculateDuration(
             name === 'startDate' ? value : formValues.startDate,
             name === 'endDate' ? value : formValues.endDate
           )
         : prevValues.duration
    }));
  };

  const handleQuillChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFormValues({
      ...formValues,
      images: files,
    });

    const previewFiles = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(previewFiles).then((images) => {
      setPreviewImages(images);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      
      // Append each field from formValues except 'images'
      for (const key in formValues) {
        if (key !== 'images') {
          formData.append(key, formValues[key]);
        }
      }
    
      // Append each image file separately
      formValues.images.forEach((image) => {
        formData.append('images', image);
      });
    
      // Send request
      const res = await PackageServices.createPackage(formData);
      console.log(res);
      if (res.status === true) {
        alert('Package added successfully');
        navigate('/admin/package');
        setPreviewImages(['img/placeholder-img.png']);
        setFormValues({
          destination: '',
          packageName: '',
          numberOfPackage:'',
          price: '',
          discountPrice: '',
          duration: '',
          startDate: '',
          endDate: '',
          cancelDate: '',
          changeDate: '',
          startLocation: '',
          endLocation: '',
          startingPoints: '',
          images: [],
          packageInclusions: '',
          policy: '',
          packageExclusions: '',
          policy: ''
        });
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add Package', error);
      alert('Failed to add Package');
    }
  };
  
  
  

  return (
    <div className="right_col" role="main">
      <Pagetitle />
      <div className="container-box">
        <div className="container-box-top-header justify-content-end">
          <div className="sub-title-box-right">
            <Link className="site-btn-green" to="/admin/package">Package List</Link>
          </div>
        </div>
        <div className="container-box-inner px-4">
          <div className="page-details">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Destination*</label>
                    <select
                      name="destination"
                      className="form-control"
                      value={formValues.destination}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select a destination</option>
                      {destinations.map((dest) => (
                        <option key={dest._id} value={dest._id}>
                          {dest.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="input-field">
                    <label>Package Name*</label>
                    <input
                      type="text"
                      name="packageName"
                      value={formValues.packageName}
                      onChange={handleInputChange}
                      placeholder="Package Name"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Start Location*</label>
                    <input
                      type="text"
                      name="startLocation"
                      value={formValues.startLocation}
                      onChange={handleInputChange}
                      placeholder="Start Location"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>End Location*</label>
                    <input
                      type="text"
                      name="endLocation"
                      value={formValues.endLocation}
                      onChange={handleInputChange}
                      placeholder="End Location"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Start Date*</label>
                    <input
                      type="date"
                      name="startDate"
                      value={formValues.startDate}
                      onChange={handleInputChange}
                      placeholder="Start Date"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>End Date*</label>
                    <input
                      type="date"
                      name="endDate"
                      value={formValues.endDate}
                      onChange={handleInputChange}
                      placeholder="End Date"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Cancel Date Limit*</label>
                    <input
                      type="date"
                      name="cancelDate"
                      value={formValues.cancelDate}
                      onChange={handleInputChange}
                      placeholder="Start Date"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Change Date Limit*</label>
                    <input
                      type="date"
                      name="changeDate"
                      value={formValues.changeDate}
                      onChange={handleInputChange}
                      placeholder="Start Date"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Duration (In Days)*</label>
                    <input
                      type="text"
                      name="duration"
                      value={formValues.duration}
                      onChange={handleInputChange}
                      placeholder="2N/3D"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Amount*</label>
                    <input
                      type="text"
                      name="price"
                      value={formValues.price}
                      onChange={handleInputChange}
                      placeholder="Amount"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Offer Amount*</label>
                    <input
                      type="text"
                      name="discountPrice"
                      value={formValues.discountPrice}
                      onChange={handleInputChange}
                      placeholder="Offer Amount"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Tax*</label>
                    <input
                      type="text"
                      name="tax"
                      value={formValues.tax}
                      onChange={handleInputChange}
                      placeholder="Tax in percentage"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Cancel Price *</label>
                    <input
                      type="text"
                      name="cancelPrice"
                      value={formValues.cancelPrice}
                      onChange={handleInputChange}
                      placeholder="Cancel Price in percentage"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Date Change Price *</label>
                    <input
                      type="text"
                      name="datechangePrice"
                      value={formValues.datechangePrice}
                      onChange={handleInputChange}
                      placeholder="Date Change Price in percentage"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Number Of Package*</label>
                    <input
                      type="number"
                      name="numberOfPackage"
                      value={formValues.numberOfPackage}
                      onChange={handleInputChange}
                      placeholder="Number Of Package"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="input-field">
                    <label>Registration Price *</label>
                    <input
                      type="text"
                      name="registrationPrice"
                      value={formValues.registrationPrice}
                      onChange={handleInputChange}
                      placeholder="150"
                      className="form-control"
                     
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-field">
                    <label>Package Inclusion</label>
                    <ReactQuill
                      theme="snow"
                      value={formValues.packageInclusions}
                      onChange={(value) => handleQuillChange('packageInclusions', value)}
                      placeholder="Package Inclusion"
                      className="form-control"
                    />
                  </div>
                </div>
             
                <div className="col-lg-12">
                  <div className="input-field">
                    <label>Booking Description</label>
                    <ReactQuill
                      theme="snow"
                      value={formValues.packageExclusions}
                      onChange={(value) => handleQuillChange('packageExclusions', value)}
                      placeholder="Description"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-field">
                    <label>Package Policy</label>
                    <ReactQuill
                      theme="snow"
                      value={formValues.policy}
                      onChange={(value) => handleQuillChange('policy', value)}
                      placeholder="Package Policy"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-field">
                    <label>Upload Images*</label>
                    <input
                      type="file"
                      name="images"
                      className="form-control"
                      multiple
                      onChange={handleFileChange}
                      required
                    />
                    <p>
                      <span>Supported formats: png, jpeg, jpg, webp</span>
                    </p>
                    <div className="file-preview text-center">
                      {previewImages.map((image, index) => (
                        <img key={index} src={image} alt={`preview ${index}`} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <button className="site-btn-green" type="submit">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPackage;
