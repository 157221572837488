import React, { useState, useEffect } from 'react';
import InvoiceServices from '../services/InvoiceServices';
import useAsync from '../hooks/useAsync';
import Pagetitle from './PageTitle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Invoice() {
    const { data, error, isLoading } = useAsync(InvoiceServices.getInvoiceDetail);

    const [formValues, setFormValues] = useState({
        name: '',
        accountNo: '',
        ifsc: '',
        accountType: '',
        policy: '',
    });

    // Update form values when data is fetched
    useEffect(() => {
        if (data?.data) {
            setFormValues({
                name: data.data.name || '',
                accountNo: data.data.accountNo || '',
                ifsc: data.data.ifsc || '',
                accountType: data.data.accountType || '',
                policy: data.data.policy || '',
            });
        }
    }, [data]);

    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // Handle ReactQuill value changes
    const handlePolicyChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            policy: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const id = data?.data?._id;
            if (id) {
                await InvoiceServices.updateInvoiceDetail(id, formValues);
                alert('Invoice details updated successfully');
            } else {
                alert('Invalid invoice ID');
            }
        } catch (error) {
            console.error('Failed to update Invoice details', error);
            alert('Failed to update Invoice details');
        }
    };

    return (
        <div className="right_col" role="main">
            <Pagetitle />
            <div className="container-box px-5">
                <div className="container-box-inner">
                    <div className="page-details">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="input-field">
                                        <label>A/C HOLDER NAME</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            value={formValues.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="input-field">
                                        <label>A/C NUMBER</label>
                                        <input
                                            type="number"
                                            name="accountNo"
                                            className="form-control"
                                            value={formValues.accountNo}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="input-field">
                                        <label>IFSC CODE</label>
                                        <input
                                            type="text"
                                            name="ifsc"
                                            className="form-control"
                                            value={formValues.ifsc}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-field">
                                        <label>A/C TYPE</label>
                                        <input
                                            type="text"
                                            name="accountType"
                                            className="form-control"
                                            value={formValues.accountType}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-field">
                                        <label>Invoice Terms & Condition</label>
                                        <ReactQuill
                                            theme="snow"
                                            value={formValues.policy}
                                            onChange={handlePolicyChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="site-btn-green">SUBMIT</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invoice;
