import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import BookServices from '../../services/BookingServies';
import PackageServices from '../../services/PackageServices';
import Footer from './Common/footer';
import axios from 'axios';

function Booking({ }) {
  const { id } = useParams();
  const decodedId = atob(id);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  // const [finalAmount, setFinalAmount] = useState(0);
  const [pkg, setPkg] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('authToken');
  const [bookingFor, setBookingFor] = useState('Myself'); // For 'Myself' or 'Someone else'
  const [children, setChildren] = useState('');
  const [passengers, setPassengers] = useState([
    { name: '', age: '', gender: '', city: '' },
    { name: '', age: '', gender: '', city: '' },
  ]); // Initial state with two default rows
  const [contact, setContact] = useState({
    name: '',
    mobileNo: '',
    email: '',
    city: '',
    state: '',
    address: '',
  });
  const [showForm, setShowForm] = useState(false); // Toggle form visibility
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await PackageServices.getPackageById(decodedId);
        setPkg(response.data); // Set the package details to `pkg`
      } catch (err) {
        setError('Error loading package details');
      }
    };
    fetchPackage();
  }, [decodedId]);
  const handlePassengerChange = (index, field, value) => {
    const newPassengers = [...passengers];
    newPassengers[index][field] = value;
    setPassengers(newPassengers);
  };
  // const handlePayment = async(e) =>{
  //   try {
  //     // Call the backend to create an order
  //     const { data } = await axios.post('http://localhost:300/create-order', {
  //       amount: amount,
  //     });

  //     // Razorpay checkout options

  //   } catch (error) {
  //     console.error('Payment creation failed', error);
  //     alert('Payment creation failed');
  //   }
  //   setLoading(false);
  // }
  // Handle bookingFor change
  const handleBookingForChange = (e) => {
    setBookingFor(e.target.value);
  };

  const handleChildrenForChange = (e) => {
    setChildren(e.target.value);
  };
  // const removePassenger = (index) => {
  //   const newPassengers = passengers.filter((_, i) => i !== index); // Filter out the passenger at the specified index
  //   setPassengers(newPassengers);
  // };
  // Function to calculate amounts
  const removePassenger = (index) => {
    // Allow removal only for rows added after the default ones
    if (index > 1) {
      const newPassengers = passengers.filter((_, i) => i !== index);
      setPassengers(newPassengers);
    }
  };
  const addPassenger = () => {
    setPassengers([...passengers, { name: '', age: '', gender: '', city: '' }]);
    // setShowForm(true); // This will only be triggered once
  };
  const handleContactChange = (field, value) => {
    setContact({ ...contact, [field]: value });
  };

  const getFilledPassengerCount = () => {
    return passengers.filter(passenger =>
      passenger.name && passenger.age && passenger.gender && passenger.city
    ).length;
  };
  const memberquantiy = getFilledPassengerCount();
  const calculateAmounts = () => {
    const filledPassengerCount = getFilledPassengerCount(); // Get the count of filled passengers
    const basePrice = pkg.discountPrice || 0;
    const totalPrice = basePrice * filledPassengerCount;
    const taxPercentage = pkg.tax || 0;
    const taxAmount = (totalPrice * taxPercentage) / 100;
    const totalAmount = totalPrice + taxAmount;
    return totalAmount;    // setFinalAmount(finalAmount);
  };
  // const handleBookingSubmit = async () => {
  //   if (!pkg) {
  //     console.error('Package details are not available');
  //     return;
  //   }
  //   const underagePassenger = passengers.some(passenger => passenger.age < 18);
  //   if (underagePassenger) {
  //     alert('Age must be 18 or above');
  //     return;  // Stop further execution if validation fails
  //   }
  //   const finalAmount = calculateAmounts();
  //   // console.log(finalAmount)
  //   // Construct booking data with necessary fields
  //   const bookingData = {
  //     packageId: pkg._id,
  //     startDate: pkg.startDate,
  //     endDate: pkg.endDate,
  //     totalAmount: finalAmount, 
  //     registrationFees:pkg.registrationPrice,      // Use the calculated totalAmount (now the final amount)
  //     bookingFor,
  //     tax: pkg.tax,       // Use the package's tax field
  //     quantity: memberquantiy,  // Quantity of passengers
  //     passengers,
  //     contact,
  //     userId,             // User ID from localStorage
  //   };

  //   try {
  //     // Send booking data to the backend
  //     const response = await BookServices.createBooking(bookingData);

  //     //  console.log(response)
  //     const options = {
  //       key: 'rzp_live_GSX6yECeAmVRxm',    // Replace with your Razorpay Key ID
  //       amount: finalAmount,
  //       currency: "INR",
  //       name: 'Bag',
  //       description: 'Payment for your order',
  //       image: 'https://your-logo-url.com/logo.png',
  //       order_id: response.data.razorpayOrderId, // The order ID returned from the backend
  //       handler: async function (paymentResponse) {

  //         try {
  //           // Call verify API after successful payment
  //           const paymentVerification = await BookServices.verifyPayment({
  //             paymentId: paymentResponse.razorpay_payment_id,
  //             orderId: response.data.razorpayOrderId,
  //             signature: paymentResponse.razorpay_signature,
  //             bookingId: response.data.booking.id,
  //           });
  //           // console.log("paymentVerification:",paymentVerification)      
  //           if (paymentVerification.status === true) {
  //             alert('Payment verified successfully. Your booking is confirmed.');
  //             navigate('/order');
  //           } else {
  //             alert('Payment verification failed. Please try again.');
  //           }
  //         } catch (error) {
  //           alert('Payment verification error.');
  //           console.error('Error during verification:', error);
  //         }
  //       },
  //       prefill: {
  //         name: response.data.booking.contact.name,
  //         email: response.data.booking.contact.email,
  //         contact: response.data.booking.contact.mobileNo,
  //       },
  //       notes: {
  //         bookingId: response.data.booking.id // Attach bookingId in notes
  //       },
  //     };

  //     // Trigger Razorpay checkout
  //     const razorpay = new window.Razorpay(options);
  //     razorpay.open();

  //     // console.log(razorpay)
  //     // console.log('Booking created successfully', response);
  //     alert(response.data.message || 'Booking created successfully');
  //     // Verify payment after booking creation



  //     // navigate('/order');
  //   } catch (error) {
  //     // Handle any errors during booking creation
  //     alert(error.response?.data?.message || "An error occurred while creating the booking.");
  //     console.error('Booking failed', error);
  //   }
  // };

  const handleBookingSubmit = async () => {
    if (!pkg) {
      console.error('Package details are not available');
      return;
    }

    const underagePassenger = passengers.some(passenger => passenger.age < 18);
    if (underagePassenger) {
      alert('Age must be 18 or above');
      return;  // Stop further execution if validation fails
    }

    const finalAmount = calculateAmounts();

    // Check registration fees, if greater than 0, separate amount should be paid
    let paymentAmount = finalAmount;  // Default to total amount
    if (pkg.registrationPrice > 0) {
      paymentAmount = pkg.registrationPrice;  // Only registration fee if greater than 0
    }

    // Construct booking data with necessary fields
    const bookingData = {
      packageId: pkg._id,
      startDate: pkg.startDate,
      endDate: pkg.endDate,
      totalAmount: finalAmount,
      registrationFees: pkg.registrationPrice, // Use the calculated totalAmount (now the final amount)
      bookingFor,
      tax: pkg.tax, // Use the package's tax field
      quantity: memberquantiy, // Quantity of passengers
      passengers,
      contact,
      userId, // User ID from localStorage
      numberOfChildren:children
    };

    try {
      // Send booking data to the backend
      const response = await BookServices.createBooking(bookingData);

      const { razorpayOrderId, booking } = response.data;

      const options = {
        key: 'rzp_live_GSX6yECeAmVRxm', // Replace with your Razorpay Key ID
        amount: paymentAmount,  // Use the determined payment amount (registration fee or total amount)
        currency: "INR",
        name: 'Bag',
        description: 'Payment for your order',
        image: 'https://your-logo-url.com/logo.png',
        order_id: razorpayOrderId, // The order ID returned from the backend
        handler: async function (paymentResponse) {
          try {
            // Call verify API after successful payment
            const paymentVerification = await BookServices.verifyPayment({
              ...bookingData, // Spread booking data to include all necessary fields
              paymentId: paymentResponse.razorpay_payment_id,
              signature: paymentResponse.razorpay_signature,
            });

            if (paymentVerification.status === true) {
              alert(' Payment Successful! Thank you for your order.');
             
              navigate('/order');
            } else {
              alert('Payment Failed. Please try again or use a different payment method.');
            }
          } catch (error) {
            alert('Payment verification error.');
            console.error('Error during verification:', error);
          }
        },
      };

      // Trigger Razorpay checkout
      const razorpay = new window.Razorpay(options);
      razorpay.open();



    } catch (error) {
      // Handle any errors during booking creation
      alert(error.response?.data?.message || "An error occurred while creating the booking.");
      console.error('Booking failed', error);
    }
  };

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const formatDateMonth = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // "22 Oct, 2024"
  };
  const formatDay = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${dayOfWeek}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  if (error) return <div>{error}</div>;
  if (!pkg) return <div>Loading package details...</div>;
  return (
    <>
      <section className="notification-section booking-section">
        <div className="container">
          <div className="tour-package-outer">
            <h3 className="banner-heading">Booking</h3>
          </div>
        </div>
      </section>
      <section className="all-package-section">
        <div className="container">
          <div className="breadcrumb-outer">
            <ul className="breadcrumb">
              <li><a href="/">Home</a></li>
              <li> <a href="/tour-package" className="breadcrumb-li">  Tour Package</a></li>
              <li> <Link to={`/package-details/${pkg._id}`} className="breadcrumb-li">Package Details</Link> </li>
              <li>Booking</li>
            </ul>
          </div>
          <div className="heading-outer d-flex">
            <div className="main-heading-outer">
              <h3 className="main-heading">Booking</h3>
            </div>
            <div className="view-all-btn">
              <a href="#"> </a>
            </div>
          </div>
          <div className="notification-inner">
            <div className="row">
              <div className="col-lg-4">
                <h5 className="heading" style={{ marginBottom: 10 }}>
                  {pkg.packageName}
                </h5>
                <p className="sub-heading" style={{ marginBottom: 10 }}>
                  {pkg.startLocation} to {pkg.endLocation}
                </p>
              </div>
              <div className="col-lg-4">
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading " style={{ fontSize: 16 }}>
                      {formatDateMonth(pkg.startDate)}
                    </h5>
                    <p className="sub-heading" style={{ marginBottom: 10 }}>
                      {formatDay(pkg.startDate)}
                    </p>
                  </div>
                  <div className="booking-outer-3">
                    <a href="#" className=" package-day" style={{ background: "rgba(108, 112, 114, 0.15)", color: "#6C7072", }}>{pkg.duration}</a>
                  </div>
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16 }}>
                      {formatDateMonth(pkg.endDate)}
                    </h5>
                    <p className="sub-heading" style={{ marginBottom: 10 }}>  {formatDay(pkg.endDate)} </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-date-outer">
                  <div className="booking-outer-2">
                  </div>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="col-lg-4">
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }} >Traveller Details</h5>
                    <h5 className="heading" style={{ fontSize: 16 }}>
                      Travellers:
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}> Booking For </h5>
                    <div className="booking-choice d-flex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bookingFor"
                          value="Myself"
                          checked={bookingFor === 'Myself'}
                          onChange={handleBookingForChange}
                        />
                        <label className="form-check-label">
                          Myself
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="bookingFor"
                          value="Someone else"
                          onChange={handleBookingForChange}
                        />
                        <label className="form-check-label">
                          Someone else
                        </label>
                      </div>
                    </div>
                    <div className="change-btn Traveller-add-btn">
                      <button onClick={addPassenger} className="btn btn-primary">
                        Add Traveller
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* {showForm && ( */}
              <div className="col-lg-12">
                <div className="traveller-form">
                  {passengers.map((passenger, index) => (
                    <div key={index} className="row">
                      <div className="col-lg-3">
                        <input
                          type="text"
                          required
                          placeholder="Name"
                          className="form-control"
                          value={passenger.name}
                          onChange={(e) =>
                            handlePassengerChange(index, 'name', e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2">
                        <input
                          type="number"
                          maxLength={3}
                          required
                          placeholder="Age"
                          className="form-control"
                          value={passenger.age}
                          onChange={(e) =>
                            handlePassengerChange(index, 'age', e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2">
                        <select
                          required
                          className="form-control"
                          value={passenger.gender}
                          onChange={(e) =>
                            handlePassengerChange(index, 'gender', e.target.value)
                          }
                        >
                          <option value="">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          required
                          placeholder="City"
                          className="form-control "
                          value={passenger.city}
                          onChange={(e) =>
                            handlePassengerChange(index, 'city', e.target.value)
                          }
                        />
                      </div>
                      <div className="col-lg-2">
                        <button
                          onClick={() => removePassenger(index)} // Call remove function
                          className="btn btn-danger"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* )} */}

              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="col-lg-4">
                <div className="booking-date-outer d-flex">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}> Children Details: </h5>
                    <div className="booking-choice d-flex">
                      <div className="form-check">
                        <label style={{ marginBottom: 10 }}>Numbers Of Children</label>
                        <input
                          className='form-control'
                          type="number"
                          name="numberOfChildren"
                          onChange={handleChildrenForChange}
                        />
                      </div>                   
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="booking-outer-2">
                <h5 className="heading" style={{ fontSize: 16, marginBottom: 10 }}>
                  Contact Information
                </h5>
                <p className="sub-heading" style={{ marginBottom: 10 }}>
                  Booking details &amp; communication will be sent to -
                </p>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="name" style={{ marginBottom: 10 }}>  Name* </label>  <br />
                  <input
                    type="text"
                    className='form-control'
                    name="contact.name"
                    value={contact.name}
                    onChange={(e) => handleContactChange('name', e.target.value)}
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="email" style={{ marginBottom: 10 }}>Email Id *</label><br />
                  <input
                    type="email"
                    id="email"
                    className='form-control'
                    required
                    name="contact.email"
                    value={contact.email}
                    onChange={(e) => handleContactChange('email', e.target.value)}
                    placeholder="Eg. john.doe@gmail.com"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="mobile" style={{ marginBottom: 10 }}>Mobile *</label> <br />
                  <input
                    type="text"
                    id="phoneno"
                    className='form-control'
                    maxLength={10}
                    required
                    name="contact.mobileNo"
                    value={contact.mobileNo}
                    onChange={(e) => handleContactChange('mobileNo', e.target.value)}
                    placeholder="+91 2584655896"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="city" style={{ marginBottom: 10 }}> City *</label><br />
                  <input
                    type="text"
                    id="city"
                    className='form-control'
                    required
                    name="contact.city"
                    value={contact.city}
                    onChange={(e) => handleContactChange('city', e.target.value)}
                    placeholder="Eg. New Delhi"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="state" style={{ marginBottom: 10 }}> State *     </label>    <br />
                  <input
                    type="text"
                    id="state"
                    className='form-control'
                    required
                    name="contact.state"
                    value={contact.state}
                    onChange={(e) => handleContactChange('state', e.target.value)}
                    placeholder="State"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="booking-form">
                  <label htmlFor="address" style={{ marginBottom: 10 }}>Address *</label>
                  <br />
                  <input type="text"
                    id="address-2"
                    required
                    className='form-control'
                    name="contact.address"
                    value={contact.address}
                    onChange={(e) => handleContactChange('address', e.target.value)}
                    placeholder="Eg. House Number, Street Name,..."
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="booking-outer-2">
                  <h5 className="heading" style={{ fontSize: "16px", marginBlock: "20px", paddingBlock: "15px", borderTop: "1px solid #ddd" }}
                  >
                    Booking Description:
                  </h5>
                  {/* <p className="sub-heading" style={{ marginBottom: 10 }}>
                    {pkg.schedule.length} Hotels / {pkg.schedule.length} Activities / {pkg.schedule.length} Transfers
                  </p> */}
                  <div className='showPackages'>

                    <div className='showPackages' dangerouslySetInnerHTML={createMarkup(pkg?.packageExclusions)} />

                    {/* Iterate over the schedule array */}
                    {/* {pkg?.schedule?.map((item, index) => (
                      <div key={index}>
                        <p className="sub-heading" style={{ marginBottom: 10 }}>
                          Day {item?.day} {new Date(item?.date).toDateString()}
                        </p>
                        <div className="details-border">
                         
                          <div className="private-transfer-outer d-flex">
                            <div className="transfer-img" style={{ marginInlineEnd: 10 }}>
                              
                            </div>
                            <div className="car-content">
                              <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                Transfer
                              </h3>
                              <p className="sub-heading-2">
                                from {item?.transport?.from} to {item.transport.to} via {item?.transport?.type}
                              </p>
                            </div>
                          </div>

                         
                          <div className="private-transfer-outer d-flex">
                            <div className="transfer-img" style={{ marginInlineEnd: 10 }}>
                              
                            </div>
                            <div className="car-content">
                              <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                Check-in to Hotel: {item?.hotel?.location}
                              </h3>
                              <p className="sub-heading-2">{item?.hotel?.location} Stay</p>
                            </div>
                          </div>

                          <div className="transfer-outer d-flex">
                            <div className="transfer-img" style={{ marginInlineEnd: 18 }}>
                          
                              <img src={`${process.env.REACT_APP_URL || ''}${item?.hotel?.hotelImages[0]
                                }`} alt="Hotel Image" style={{ height: '100%', width: '150px', objectFit: 'cover' }} />
                            </div>
                            <div className="car-content">
                              <h3 className="sub-heading-2" style={{ fontSize: 16 }}>
                                {item?.hotel?.name} - {item?.hotel?.type}
                              </h3>
                              <h3 className="heading" style={{ fontSize: 14 }}>
                                {item?.location}
                              </h3>
                              <h3 className="heading" style={{ fontSize: 14, marginBlock: 5 }}>
                                {item?.hotel?.roomType}
                              </h3>
                              <h3 className="heading" style={{ fontSize: 14, color: "#595959" }}>
                                {item?.hotel?.checkIn} - {item.hotel?.checkOut}
                              </h3>
                              <h3 className="heading" style={{ fontSize: 14, color: "#595959" }}>
                                {item?.hotel?.meal && item?.hotel?.meal.length > 0 ? (
                                  item?.hotel?.meal?.map((mealItem, index) => (
                                    <span key={index}>
                                      {mealItem}
                                      {index < item?.hotel?.meal.length - 1 && ", "} 
                                    </span>
                                  ))
                                ) : (
                                  "No meals included"
                                )}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))} */}
                  </div>
                </div>
              </div>
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              {/* cancellation policy */}
              <div className="traveller-details-outer d-flex">
                <div className="booking-outer-2">
                  <h5
                    className="heading"
                    style={{ fontSize: 16, marginBottom: 15, marginInlineEnd: 40 }}
                  >
                    Cancellation &amp; Date Change...
                  </h5>
                </div>
                <div className="change-btn Traveller-add-btn" style={{ marginBlock: 0 }}>
                  {/* <a href="#">Skip to Next</a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="booking-outer-2">
                  <h5 className="heading" style={{ fontSize: 16, marginBottom: 5 }}>
                    Package Cancellation Policy
                  </h5>
                  <h5
                    className="heading"
                    style={{ fontSize: 16, marginBottom: 5, color: "#149797" }}
                  >
                    Cancellation Possible till {formatDate(pkg?.cancelDate)}*
                  </h5>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    After that Package is Non - Refundable{" "}
                  </p>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    Package Cancellation Policy
                  </p>
                </div>
                <div className="details-border">
                  <div className="private-transfer-outer d-flex">
                    <div className="car-content">
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#149797" }}
                      >
                        Till {formatDate(pkg?.cancelDate)}
                      </h3>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#1B1E28" }}
                      >
                        ₹{(pkg?.discountPrice * pkg?.cancelPrice) / 100}
                      </h3>
                      <p className="sub-heading-2">Cancellation fee</p>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#FF3232" }}
                      >
                        After {formatDate(pkg?.cancelDate)}
                      </h3>
                      <p className="sub-heading-2">
                        Non-Refundable <br />
                        Cancellation will not be allowed.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="refund-details">
                  <ul className="list-unstyled">
                    <li>
                      <div dangerouslySetInnerHTML={createMarkup(pkg?.policy)} />
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="booking-outer-2">
                  <h5 className="heading" style={{ fontSize: 16, marginBottom: 5 }}>
                    Package Date Change Policy
                  </h5>
                  <h5
                    className="heading"
                    style={{ fontSize: 16, marginBottom: 5, color: "#149797" }}
                  >
                    Date Change Possible till {formatDate(pkg?.changeDate)}*
                  </h5>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    After that Package is Non - Refundable{" "}
                  </p>
                  <p className="sub-heading" style={{ marginBottom: 5 }}>
                    Package Date Change Policy
                  </p>
                </div>
                <div className="details-border">
                  <div className="private-transfer-outer d-flex">
                    <div className="car-content">
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#149797" }}
                      >
                        Till {formatDate(pkg?.changeDate)}
                      </h3>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#1B1E28" }}
                      >
                        ₹{(pkg?.discountPrice * pkg?.datechangePrice) / 100}

                      </h3>
                      <p className="sub-heading-2">
                        Date Change Fee. Fare Difference will be extra.
                      </p>
                      <h3
                        className="sub-heading-2"
                        style={{ fontSize: 12, color: "#FF3232" }}
                      >
                        After {formatDate(pkg?.changeDate)}
                      </h3>
                      <p className="sub-heading-2">
                        Date change will <br /> not be allowed. <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
                <div className="refund-details" >
                  <ul className="list-unstyled">
                    <li>
                      <div dangerouslySetInnerHTML={createMarkup(pkg?.policy)} />
                    </li>
                  </ul>
                </div>
              <div className="col-lg-6">
                <div className="important-information-outer">
                  <div className="booking-outer-2">
                    <h5 className="heading" style={{ fontSize: 16 }}>
                      Important Information
                    </h5>
                    <p className="sub-heading" style={{ marginBottom: 10 }}>
                      {" "}
                      By clicking the Continue button below to proceed with the booking, I confirm that I have read and accept the Cancellation Policy, User Agreement, Terms and Conditions of Service, and Privacy Policy of Winja Travels.{" "}
                    </p>
                  </div>
                  <div className="information-inner d-flex">
                    <div className="booking-outer-2">
                      <p
                        className="sub-heading"
                        style={{ marginBlock: 7, color: "#595959" }}
                      >
                        {" "}
                        Please complete your booking within the specified time. The package price will be refreshed after the timer expires.{" "}
                      </p>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
              {/* end cancelllation policy */}
              <div className="day-plan-border" style={{ marginBlock: 15 }} />
              <div className="book-now-outer">
                <div className="book-now-inner d-flex">
                  <div className="book-content">
                    <h3 className="heading" style={{ fontSize: 14 }}>
                    ₹<del> {pkg?.price} </del>
                    </h3>
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Price per Passenger: {pkg?.discountPrice}
                    </h4>
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Total Passengers: {getFilledPassengerCount()} {/* Display number of filled passengers */}
                    </h4>
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Total Amount: {pkg?.discountPrice * getFilledPassengerCount()} {/* Display total amount based on filled passengers */}
                    </h4>
                    {pkg.registrationPrice !== 0 && (
                      <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                        Registration Fees: {pkg?.registrationPrice}
                      </h4>
                    )}
                    <h4 className="sub-heading-2" style={{ fontSize: 20, marginBlock: 8 }}>
                      Grand Total (after tax): ₹
                      {(
                        pkg.discountPrice * getFilledPassengerCount() +
                        (pkg.discountPrice * getFilledPassengerCount() * (pkg.tax || 0) / 100)
                      ).toFixed(2)} {/* Display final amount */}
                    </h4>
                  </div>
                  <div className="book-now-btn">
                    <button onClick={handleBookingSubmit} className="btn btn-primary">
                      Pay Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Booking;