import requests from "./httpService";

const InvoiceServices = {
    getInvoiceDetail:async () =>{
        return requests.get(`/invoice`);
    },
    updateInvoiceDetail :async (id,body) =>{
        // console.log(">>>",body)
        return requests.patch(`/invoice/${id}`,body);
    },
   

}

export default InvoiceServices;