import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AuthServices from '../../../services/AuthServices';
// import AuthContext from '../../../context/AuthContextLogin'; // Import AuthContext

function Login() {
  const [mobileOrEmail, setMobileOrEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // const { login } = useContext(AuthContext); // Use login function from AuthContext
  const navigate = useNavigate();

  // Handle mobile/email submit to send OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await AuthServices.login({ mobileOrEmail, userType: 'user' });
      console.log("response",response)
      if (response.status === true) {
        setOtpSent(true); // OTP has been sent, proceed to OTP input
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const data = await AuthServices.verifyLogin({ mobileOrEmail, otp, userType: 'user' });
      console.log(">>>",data);
      if (data.status === true) {
        localStorage.setItem('authToken', data?.token);
        localStorage.setItem('userId', data?.user?._id);
        localStorage.setItem('userRole', data?.user?.userType);
        localStorage.setItem('name', data?.user?.name);
        localStorage.setItem('userId', data?.user?.id);
        localStorage.setItem('city', data?.user?.city);
        localStorage.setItem('mobileNo', data?.user?.mobileNo);
        localStorage.setItem('image', data?.user?.image);
        // On successful verification, store user data using the context's login function
        // login({
        //   name: data?.data?.name,
        //   image: data?.data?.image,
        //   token: data?.token, // Save token in the context (you can use this for further authentication)
        // });

        // Redirect to the home page or dashboard
        navigate('/');
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
    
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-body login-new-bg">
      <div className="login-container">
        <div className="login-left">
          <img
            src="img/login-img-left.png"
            alt="Login"
            className="login-img"
            style={{ width: 'auto', height: '600px' }}
          />
        </div>
        <div className="login-right">
          <form id="login-form" onSubmit={otpSent ? handleVerifyOtp : handleSubmit}>
            <h2>Login</h2>
            <h4>Login Your Account</h4>
            <div className="form-group">
              <label htmlFor="username" className="icon-container">
                <i className="fa fa-phone" />
              </label>
              <input
                type="text"
                id="username"
                name="mobileOrEmail"
                placeholder="Enter your mobile No."
                maxLength={10}
                value={mobileOrEmail}
                onChange={(e) => setMobileOrEmail(e.target.value)}
                required
                disabled={otpSent}
              />
            </div>

            {otpSent && (
              <>
                <div className="form-group">
                  <label htmlFor="otp" className="icon-container">
                    <i className="fa fa-key" />
                  </label>
                  <input
                    type="text"
                    id="password"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}                   
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <div className="resend-otp-button text-center mb-3">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Resend OTP
                  </button>
                </div>
              </>
            )}

            <div className="text-center">
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <button type="submit" className="button-login-btn" disabled={loading}>
                {loading ? 'Processing...' : otpSent ? 'Verify OTP' : 'Login'}
              </button>
            </div>

            {/* Corrected signup link */}
            <p className="text-center">
              Don’t have an account? <Link to="/signup" style={{color: "#fff", fontWeight: "600"}}>Sign up</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
