import React, { useState } from 'react';
import PackageServices from '../../services/PackageServices';
import ReactQuill from 'react-quill';
function AddDay({ pkg, closeModal }) {
  const initialSchedule = {
    day: '',
    date: '',
    location: '',
    activities: '',
    hotel: {
      hotelImages: [],
      name: '',
      location: '',
      address: '',
      roomType: '',
      meal: '',
      checkIn: '',
      checkOut: ''
    },
    transport: {
      from: '',
      to: '',
      type: '',
      vehicle: '',
      description: '',
      guide: ''
    }
  };
  const [previewImages, setPreviewImages] = useState([]);
  const [formValues, setFormValues] = useState({ schedule: [initialSchedule] });
  const [uploading, setUploading] = useState(false);

  const handleQuillChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };
  const handleInputChange = (e, index, field) => {
    const { name, value } = e.target;

    setFormValues(prevValues => {
      const updatedSchedule = [...prevValues.schedule];
      if (field === 'hotel' || field === 'transport') {
        updatedSchedule[index][field] = {
          ...updatedSchedule[index][field],
          [name]: value
        };
      } else {
        updatedSchedule[index][name] = value;
      }
      return { schedule: updatedSchedule };
    });
  };

  const handleAddSchedule = () => {
    setFormValues(prevValues => ({
      schedule: [...prevValues.schedule, initialSchedule]
    }));
  };

  const handleFileChange = async (event, index) => {
    const files = Array.from(event.target.files); // Get the selected files
    setUploading(true);

    try {
      // Upload each file and retrieve the uploaded image URLs
      const uploadedImages = await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('image', file);
          const response = await PackageServices.uploadImage(formData); // Upload image

          // Assuming response contains hotelImages as an array
          // console.log("Uploaded image response:", response); // Log the response to verify
          return response.hotelImages; // Get the hotelImages directly from the response
        })
      );

      // Flatten the uploadedImages array since each response might return an array
      const flatUploadedImages = uploadedImages.flat();

      // Update the formValues with the uploaded image URLs
      setFormValues((prevState) => {
        // Map over the schedule to update the correct schedule item by index
        const updatedSchedule = prevState.schedule.map((item, i) => {
          if (i === index) {
            // Merge the new uploaded images with the existing ones
            return {
              ...item,
              hotel: {
                ...item.hotel,
                hotelImages: [...item.hotel.hotelImages, ...flatUploadedImages], // Append the new image URLs
              },
            };
          }
          return item; // Return other items as is
        });
        // console.log("Updated schedule:", updatedSchedule); // Log the updated schedule for debugging
        return { schedule: updatedSchedule };
      });
    } catch (error) {
      console.error('Image upload failed', error); // Log the error if image upload fails
      alert('Failed to upload images');
    } finally {
      setUploading(false); // Reset the uploading state
    }
  };

  const handleRemoveImage = async (index, imageUrl) => {
    try {
      // Send the image URL to the API to remove the image
      const response = await PackageServices.removeImage({ url: imageUrl });

      if (response.status === true) {
        // If the image is successfully removed, update the state
        setFormValues(prevState => {
          const updatedSchedule = prevState.schedule.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                hotel: {
                  ...item.hotel,
                  hotelImages: item.hotel.hotelImages.filter(image => image !== imageUrl)
                }
              };
            }
            return item;
          });
          return { ...prevState, schedule: updatedSchedule };
        });

        alert("Image removed successfully!");
      } else {
        alert("Failed to remove image from server");
      }
    } catch (error) {
      console.error('Failed to remove image', error);
      alert('Failed to remove image');
    }
  };

  const handleRemoveSchedule = (index) => {
    setFormValues(prevValues => ({
      schedule: prevValues.schedule.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Prepare the request payload directly from the formValues
      const payload = {
        schedule: formValues.schedule   // Include the full schedule with hotelImages and other details
      };
      // console.log('payload', payload)
      // Send the schedule data to the backend via your service
      const res = await PackageServices.addDayToSchedule(pkg._id, payload);

      if (res.status === 200 || res.success) {
        alert('Schedule and images added successfully');
        closeModal(); // Close the modal after successful submission
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Failed to add schedule and images', error);
      alert('Failed to add schedule and images');
    }
  };

  return (
    <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Day to Package</h5>
            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="container-box-inner">
              <div className="page-details">
                <form onSubmit={handleSubmit}>
                  {formValues.schedule.map((item, index) => (
                    <div key={index} className="schedule-section">
                      <h6>Day {index + 1}</h6>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input-field">
                            <label>Day</label>
                            <input type="number" name="day" placeholder='1' value={item.day} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" required />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-field">
                            <label>Date</label>
                            <input type="date" name="date" placeholder='YYYY-MM-DD' value={item.date} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input-field">
                            <label>Location</label>
                            <input type="text" name="location" placeholder='Shimala' value={item.location} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="input-field">
                            <label>Activities</label>
                            {/* <textarea name="activities" placeholder='Riding,skipper' value={item.activities} onChange={(e) => handleInputChange(e, index, 'schedule')} className="form-control" /> */}
                            <ReactQuill
                              theme="snow"
                              name="activities"
                              onChange={(value) => handleInputChange({ target: { name: 'activities', value } }, index, null)}
                              value={formValues.schedule[index].activities}
                              placeholder="Riding, skipper"
                              className="form-control"
                            />

                          </div>
                        </div>
                        <div className="col-md-12">
                          <h6>Hotel Details</h6>
                        </div>
                        {['name', 'location', 'address', 'roomType', 'meal', 'checkIn', 'checkOut'].map((field) => (
                          <div className="col-md-4" key={field}>
                            <div className="input-field">
                              <label>{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
                              <input type={field.includes('date') ? 'date' : 'text'} name={field} value={item.hotel[field]} onChange={(e) => handleInputChange(e, index, 'hotel')} className="form-control"
                                placeholder={item.hotel[field] || `Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                              />
                            </div>
                          </div>
                        ))}
                        <div className="col-sm-6">
                          <div className="input-field">
                            <label>Upload Images</label>
                            <input type="file" name="hotel.hotelImages" className="form-control" multiple onChange={(e) => handleFileChange(e, index)} />
                            <p><span>Supported formats: png, jpeg, jpg, webp</span></p>
                            <div className="file-preview text-center">
                              {item.hotel.hotelImages.length > 0 && item.hotel.hotelImages.map((imageUrl, idx) => (
                                <div key={idx} style={{ position: 'relative', display: 'inline-block' }}>
                                  <img src={`${process.env.REACT_APP_URL}${imageUrl}`} alt={`preview ${idx}`} style={{ maxWidth: '100px', margin: '5px' }} />
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveImage(index, imageUrl)}
                                    style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      background: 'red',
                                      color: 'white',
                                      border: 'none',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                              ))}
                            </div>

                          </div>
                        </div>
                        <div className="col-md-12">
                          <h6>Transport Details</h6>
                        </div>
                        {['from', 'to', 'type', 'vehicle', 'description', 'guide'].map((field) => (
                          <div className="col-md-4" key={field}>
                            <div className="input-field">
                              <label>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                              <input type="text" name={field} value={item.transport[field]} onChange={(e) => handleInputChange(e, index, 'transport')} className="form-control" required
                                placeholder={item.transport[field] || `Enter ${field.charAt(0).toUpperCase() + field.slice(1)}`}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <button type="button" onClick={() => handleRemoveSchedule(index)} className="btn btn-danger">Remove Schedule</button>
                    </div>
                  ))}
                  <button type="button" onClick={handleAddSchedule} className="btn btn-secondary">Add More Days</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDay;





// import React, { useState } from 'react';
// import PackageServices from '../../services/PackageServices';

// function AddDay({ pkg, closeModal }) {

//   const [formValues, setFormValues] = useState({
//     schedule: [
//       {
//         day: '',
//         date: '',
//         location: '',
//         activities: '',
//         hotel: {
//           hotelImages: [],
//           name: '',
//           location: '',
//           address: '',
//           roomType: '',
//           meal: '',
//           checkIn: '',
//           checkOut: ''
//         },
//         transport: {
//           from: '',
//           to: '',
//           type: '',
//           vehicle: ''
//         }
//       }
//     ]
//   });
//   const [previewImages, setPreviewImages] = useState([]);
//   const handleInputChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedSchedule = [...formValues.schedule];
//     updatedSchedule[index] = {
//       ...updatedSchedule[index],
//       [name]: value
//     };
//     setFormValues({ schedule: updatedSchedule });
//   };

// const handleScheduleChange = (e, field, index) => {
//   const { name, value } = e.target;
//   const updatedSchedule = [...formValues.schedule];
//   updatedSchedule[index][field] = value;
//   setFormValues({ schedule: updatedSchedule });
// };



//   const handleHotelChange = (e, field, index) => {
//     const { name, value } = e.target;
//     const updatedSchedule = [...formValues.schedule];
//     updatedSchedule[index].hotel[field] = value;
//     setFormValues({ schedule: updatedSchedule });
//   };

//   const handleTransportChange = (e, field, index) => {
//     const { name, value } = e.target;
//     const updatedSchedule = [...formValues.schedule];
//     updatedSchedule[index].transport[field] = value;
//     setFormValues({ schedule: updatedSchedule });
//   };

//   const handleAddSchedule = () => {
//     setFormValues(prevValues => ({
//       schedule: [...prevValues.schedule, {
//         day: '',
//         date: '',
//         location: '',
//         activities: '',
//         hotel: {
//           hotelImages: [],
//           name: '',
//           location: '',
//           address: '',
//           roomType: '',
//           meal: '',
//           checkIn: '',
//           checkOut: ''
//         },
//         transport: {
//           from: '',
//           to: '',
//           type: '',
//           vehicle: ''
//         }
//       }]
//     }));
//   };


//   const handleFileChange = (event, index) => {
//     const files = Array.from(event.target.files);
//     setFormValues((prevState) => ({
//       ...prevState,
//       schedule: prevState.schedule.map((item, i) => {
//         if (i === index) { // Ensure the correct day is targeted
//           return {
//             ...item,
//             hotel: {
//               ...item.hotel,
//               hotelImages: files, // Store the selected files in the state
//             },
//           };
//         }
//         return item;
//       }),
//     }));
//   };


//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const formData = new FormData();

//       formValues.schedule.forEach((scheduleItem, index) => {
//         for (const key in scheduleItem) {
//           if (key === 'hotel') {
//             for (const hotelKey in scheduleItem.hotel) {
//               if (hotelKey === 'hotelImages') {
//                 scheduleItem.hotel.hotelImages.forEach((image) => {
//                   formData.append(`schedule[${index}].hotel.hotelImages`, image);
//                 });
//               } else {
//                 formData.append(`schedule[${index}].hotel.${hotelKey}`, scheduleItem.hotel[hotelKey]);
//               }
//             }
//           } else if (key === 'transport') {
//             for (const transportKey in scheduleItem.transport) {
//               formData.append(`schedule[${index}].transport.${transportKey}`, scheduleItem.transport[transportKey]);
//             }
//           } else {
//             formData.append(`schedule[${index}].${key}`, scheduleItem[key]);
//           }
//         }
//       });

//       const res = await PackageServices.addDayToSchedule(pkg._id, formData);
//       if (res.status === true) {
//         alert('Schedule added successfully');
//         closeModal();
//       } else {
//         alert('Something went wrong');
//       }
//     } catch (error) {
//       console.error('Failed to add schedule', error);
//       alert('Failed to add schedule');
//     }
//   };
//   const handleRemoveSchedule = (index) => {
//     const updatedSchedule = formValues.schedule.filter((_, i) => i !== index);
//     setFormValues({ schedule: updatedSchedule });
//   };

//   return (
//     <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
//       <div className="modal-dialog modal-dialog-centered">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title">Add Day to Package</h5>
//             <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
//           </div>
//           <div className="modal-body">
//             <div className="container-box-inner">
//               <div className="page-details">
//                 <form onSubmit={handleSubmit}>
//                   {formValues.schedule.map((item, index) => (
//                     <div key={index} className="schedule-section">
//                       <h6>Day {index + 1}</h6>
//                       <div className="row">
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Day</label>
//                             <input
//                               type="text"
//                               name="day"
//                               value={item.day}
//                               onChange={(e) => handleScheduleChange(e, 'day', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Date</label>
//                             <input
//                               type="date"
//                               name="date"
//                               value={item.date}
//                               onChange={(e) => handleScheduleChange(e, 'date', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Location</label>
//                             <input
//                               type="text"
//                               name="location"
//                               value={item.location}
//                               onChange={(e) => handleScheduleChange(e, 'location', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-12">
//                           <div className="input-field">
//                             <label>Activities</label>
//                             <textarea
//                               name="activities"
//                               value={item.activities}
//                               onChange={(e) => handleScheduleChange(e, 'activities', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-12">
//                           <h6>Hotel Details</h6>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Hotel Name</label>
//                             <input
//                               type="text"
//                               name="hotel.name"
//                               value={item.hotel.name}
//                               onChange={(e) => handleHotelChange(e, 'name', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>

//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Hotel Location</label>
//                             <input
//                               type="text"
//                               name="hotel.location"
//                               value={item.hotel.location}
//                               onChange={(e) => handleHotelChange(e, 'location', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Hotel Address</label>
//                             <input
//                               type="text"
//                               name="hotel.address"
//                               value={item.hotel.address}
//                               onChange={(e) => handleHotelChange(e, 'address', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Room Type</label>
//                             <input
//                               type="text"
//                               name="hotel.roomType"
//                               value={item.hotel.roomType}
//                               onChange={(e) => handleHotelChange(e, 'roomType', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Meal</label>
//                             <input
//                               type="text"
//                               name="hotel.meal"
//                               value={item.hotel.meal}
//                               onChange={(e) => handleHotelChange(e, 'meal', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Check-In Date</label>
//                             <input
//                               type="date"
//                               name="hotel.checkIn"
//                               value={item.hotel.checkIn}
//                               onChange={(e) => handleHotelChange(e, 'checkIn', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Check-Out Date</label>
//                             <input
//                               type="date"
//                               name="hotel.checkOut"
//                               value={item.hotel.checkOut}
//                               onChange={(e) => handleHotelChange(e, 'checkOut', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-sm-6">
//                           <div className="input-field">
//                             <label>Upload Images</label>
//                             <input
//                               type="file"
//                               name="hotel.hotelImages"
//                               className="form-control"
//                               multiple
//                               onChange={(e) => handleFileChange(e, index)} // Pass the correct index for file handling
//                             />

//                             <p>
//                               <span>Supported formats: png, jpeg, jpg, webp</span>
//                             </p>
//                             <div className="file-preview text-center">
//                               {item.hotel.hotelImages.map((image, idx) => (
//                                 <img key={idx} src={URL.createObjectURL(image)} alt={`preview ${idx}`} />
//                               ))}
//                             </div>
//                           </div>
//                         </div>

//                         <div className="col-md-12">
//                           <h6>Transport Details</h6>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>From</label>
//                             <input
//                               type="text"
//                               name="transport.from"
//                               value={item.transport.from}
//                               onChange={(e) => handleTransportChange(e, 'from', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>To</label>
//                             <input
//                               type="text"
//                               name="transport.to"
//                               value={item.transport.to}
//                               onChange={(e) => handleTransportChange(e, 'to', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Type</label>
//                             <input
//                               type="text"
//                               name="transport.type"
//                               value={item.transport.type}
//                               onChange={(e) => handleTransportChange(e, 'type', index)}
//                               className="form-control"
//                             />
//                           </div>
//                         </div>
//                         <div className="col-md-4">
//                           <div className="input-field">
//                             <label>Vehicle</label>
//                             <input
//                               type="text"
//                               name="transport.vehicle"
//                               value={item.transport.vehicle}
//                               onChange={(e) => handleTransportChange(e, 'vehicle', index)}
//                               className="form-control"
//                             />
//                           </div>

//                         </div>
//                         <div className="col-md-12 text-end">
//                           {formValues.schedule.length > 1 && (
//                             <button
//                               type="button"
//                               onClick={() => handleRemoveSchedule(index)}
//                               className="btn btn-danger"
//                             >
//                               Remove Day
//                             </button>
//                           )}
//                         </div>
//                       </div>
//                     </div>

//                   ))}
//                   <button type="button" onClick={handleAddSchedule} className="btn btn-secondary">Add Another Day</button>
//                   <button type="submit" className="site-btn-green">Submit</button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AddDay;
