import React, { useState } from 'react'
import BookingServices from '../../services/BookingServies';

function CancelBooking({booking,page,closeModal,onSuccess}) {
    const [ongoingBookings, setOngoingBookings] = useState([]);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [cancelBookings, setCancelBookings] = useState([]);
    const [completedBookings, setCompletedBookings] = useState([]);
    const [selectedEdit, setSelectedEdit] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
console.log('>>>>>',booking)
    const onCancelBooking = async (bookingId) => {
        try {
            const response = await BookingServices.cancelBooking(booking._id, { status: "Cancelled" });

            if (response.status === 200) {
                // Update ongoing bookings after cancellation
                setOngoingBookings(ongoingBookings?.filter(booking => booking._id !== bookingId));
                setCancelBookings([...cancelBookings, { ...ongoingBookings.find(b => b._id === bookingId), status: "Cancelled" }]);
                // Show success message in alert
                alert(response.data.message || "Booking cancelled successfully.");
                closeModal();
                onSuccess();
            } else {
                // console.error("Booking cancelled successfully");
                alert("Booking cancelled successfully.");
                closeModal();
                onSuccess();
            }
        } catch (error) {
            console.error("Error canceling booking:", error);
            alert(error.response?.data?.message || "An error occurred while canceling the booking.");
        }
    };


    return (
        <div className="modal fade show d-block" id="deleteACModel" tabIndex="-1" aria-labelledby="deleteACModelLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-bg-light-green">
                    <div className="modal-body py-4 px-5 text-center">
                        <h4>Cancel this Booking?</h4>
                        <p className="desc my-2">Are you sure you want to cancel this booking? This action cannot be undone.</p>
                        <div className="footer-btn-grp text-center py-4">
                            <button className="site-btn btn-o btn" onClick={closeModal}>No</button>
                            <button className="site-btn btn-red delete-btn" onClick={onCancelBooking}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelBooking