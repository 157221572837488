import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminServices from '../../services/AdminServices';
import ReCAPTCHA from 'react-google-recaptcha';

const PasswordToggle = ({ password, setPassword }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="form-group">
      <label htmlFor="password">
        <i className="fa fa-lock" />
      </label>
      <input
        type={passwordVisible ? 'text' : 'password'}
        className="form-control"
        id="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <span
        className={`fa ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
        onClick={togglePasswordVisibility}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

function Login({ setIsAuthenticated, setRole }) {
  const [mobileOrEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const recaptchaRef = useRef();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // if (!recaptchaToken) {
    //   setError('Please complete the reCAPTCHA verification.');
    //   setLoading(false);
    //   return;
    // }

    try {
      const data = await AdminServices.login({ 
        mobileOrEmail, 
        password, 
        recaptchaToken 
      });

      if (data.status === true) {
        setOtpSent(true);
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const data = await AdminServices.verifyLogin({ mobileOrEmail, otp });
      if (data.status === true) {
        console.log(">>>>>>>>>",data)
        localStorage.setItem('authToken', data?.token);
        localStorage.setItem('userRole', data?.data?.userType);
        localStorage.setItem('name', data?.data?.name);
        localStorage.setItem('image', data?.data?.image);
        navigate('/admin/dashboard');
      } else {
        setError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message || 'An unexpected error occurred. Please try again.';
      setError(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-bg">
      <div className="login-container">
        <div className="login-left">
          <img src="img/login-img.png" alt="Login-bg" className="login-img" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        <div className="login-right">
          <form onSubmit={otpSent ? handleVerifyOtp : handleSubmit}>
            <img src="img/logo.png" alt="Logo" className="login-img" style={{ maxWidth: '40%', height: 'auto', display: 'block', marginInline: 'auto' }} />
            <h2 style={{ color: 'white' }}>Login</h2>

            <div className="form-group">
              <label htmlFor="username">
                <i className="fa fa-envelope" />
              </label>
              <input
                type="email"
                className="form-control"
                id="userEmail"
                placeholder="example@example.com"
                value={mobileOrEmail}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <PasswordToggle password={password} setPassword={setPassword} />

            {otpSent && (
              <div className="form-group">
                <label htmlFor="otp">
                  <i className="fa fa-key" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=" password otp"
                  placeholder="Enter OTP"
                  style={{paddingInline :"65px"}}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
            )}
            
            <ReCAPTCHA
              sitekey="6LeOGqwqAAAAAMUuiH2luItPL3kuCiz8io4IHJwS"
              size="normal"
              ref={recaptchaRef}
              onChange={(token) => setRecaptchaToken(token)}
            />
            
            {error && <h6 style={{ color: 'red' }}>{error}</h6>}

            <div className="text-center">
              <button type="submit" className="login-btn" disabled={loading}>
                {loading ? 'Processing...' : otpSent ? 'Verify OTP' : 'Login'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
