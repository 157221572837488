import React, { useState, useEffect } from 'react';
import useAsync from '../../hooks/useAsync';
import Footer from './Common/footer';
import BookingServices from '../../services/BookingServies';
import CancelButton from '../Button/CancelBooking';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

function Order() {
    const { data, run, error, isLoading } = useAsync(BookingServices.getMyBooking);
    // console.log("data", data)
    const [ongoingBookings, setOngoingBookings] = useState([]);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [cancelBookings, setCancelBookings] = useState([]);
    const [completedBookings, setCompletedBookings] = useState([]);
    const [selectedEdit, setSelectedEdit] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleCancel = (booking) => {
        setSelectedEdit(booking);
        setIsCancelModalOpen(true);
        toggleActionMenu(null);
    };

    const closeCancelModal = () => {
        setIsCancelModalOpen(false);
        setSelectedEdit(null);
    };

    // Update bookings when data is loaded
    useEffect(() => {
        if (data?.data) {
            setOngoingBookings(data?.data?.filter(booking => booking?.status !== "Completed" && booking.status !== "Cancelled"));
            setCompletedBookings(data?.data?.filter(booking => booking?.status === "Completed"));
            setCancelBookings(data?.data?.filter(booking => booking?.status === "Cancelled"));
        }
    }, [data]);

    // Function to handle booking cancellation
    // const onCancelBooking = async (bookingId) => {
    //     try {
    //         const response = await BookingServices.cancelBooking(bookingId, { status: "Cancelled" });

    //         if (response.status === 200) {
    //             // Update ongoing bookings after cancellation
    //             setOngoingBookings(ongoingBookings?.filter(booking => booking._id !== bookingId));
    //             setCancelBookings([...cancelBookings, { ...ongoingBookings.find(b => b._id === bookingId), status: "Cancelled" }]);
    //             // Show success message in alert
    //             alert(response.data.message || "Booking cancelled successfully.");
    //         } else {
    //             // console.error("Booking cancelled successfully");
    //             alert("Booking cancelled successfully.");
    //         }
    //     } catch (error) {
    //         console.error("Error canceling booking:", error);
    //         alert(error.response?.data?.message || "An error occurred while canceling the booking.");
    //     }
    // };
    const toggleActionMenu = (index) => {
        setActiveIndex(index === activeIndex ? null : index); // Toggle the active index
    };
    // Function to handle invoice download
    const onDownloadInvoice = async (bookingId) => {
        try {
            // Make API call to get the invoice URL
            const response = await BookingServices.downloadInvoice(bookingId);
            if (response.status === 200 || response.url) {
                // Open the invoice URL in a new tab
                window.open(`http://143.244.135.240:3002/${response.url}`, '_blank');
            } else {
                alert("Unable to download invoice. Please try again later.");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
            alert("An error occurred while downloading the invoice.");
        }
    };

    const onDownloadCancelInvoice = async (bookingId) => {
        try {
            // Make API call to get the invoice URL
            const response = await BookingServices.downloadCancelInvoice(bookingId);
            if (response.status === 200 || response.url) {
                // Open the invoice URL in a new tab
                // window.open(`${process.env.REACT_APP_URL}/${response.url}`, '_blank');
                window.open(`http://143.244.135.240:3002/${response.url}`, '_blank');
            } else {
                alert("Unable to download invoice. Please try again later.");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
            alert("An error occurred while downloading the invoice.");
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${day}-${month}-${year}`;
    };
    const formatDateMonth = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options); // "22 Oct, 2024"
    };
    const formatDay = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        // Get the day of the week (e.g., Sunday, Monday, etc.)
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOfWeek = daysOfWeek[date.getDay()];

        return `${dayOfWeek}`;
    };
    return (
        <>
            <section className="notification-section order-section">
                <div className="container">
                    <div className="tour-package-outer">
                        <h3 className="banner-heading">Order</h3>
                    </div>
                </div>
            </section>

            <section className="all-package-section">
                <div className="container">
                    <div className="breadcrumb-outer">
                        <ul className="breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Order</li>
                        </ul>
                    </div>
                    <div className="heading-outer d-flex">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="main-heading">My Booking</li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                    Ongoing
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                    Completed
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-cancel-tab" data-bs-toggle="pill" data-bs-target="#pills-cancel" type="button" role="tab" aria-controls="pills-cancel" aria-selected="false">
                                    Cancelled
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content" id="pills-tabContent">
                        {/* Ongoing Bookings */}
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row">

                                {ongoingBookings?.map(booking => (
                                    <div className="col-lg-4" key={booking._id}>
                                        <div className="download-outer">
                                            <Link to={`/view-booking/${booking._id}`}>
                                                <div className="download-outer-inner">
                                                    <div className="booking-outer">
                                                        <h5 className="heading" style={{ marginBottom: 10 }}>{booking?.packageName}</h5>
                                                        <p className="sub-heading" style={{ marginBottom: 10 }}>{booking?.duration}</p>
                                                        <p className="heading" style={{ fontSize: 16, marginBottom: 10, color: "#1B1E28" }}>
                                                            {booking.passengers.length} Travellers:
                                                            <span>
                                                                {booking.passengers.filter(p => p.age >= 15).length} Adults,
                                                                {booking.numberOfChildren} Child
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="booking-date-outer d-flex" >
                                                        <div className="booking-outer-2">
                                                            <h5 className="heading" style={{ fontSize: 16 }}>{formatDateMonth(booking?.startDate)}</h5>
                                                            <p className="sub-heading" style={{ marginBottom: 10 }}>{formatDay(booking?.startDate)}</p>
                                                        </div>
                                                        <div className="booking-outer-2">
                                                            <h5 className="heading" style={{
                                                                fontSize: 16,
                                                                background: 'rgba(108, 112, 114, 0.15)',
                                                                padding: '3px 10px',
                                                                borderRadius: '50px',
                                                                color: '#6C7072',
                                                            }}
                                                            >{booking?.duration}</h5>
                                                        </div>
                                                        <div className="booking-outer-2">                                                       <h5 className="heading" style={{ fontSize: 16 }}>{formatDateMonth(booking?.endDate)}</h5>
                                                            <p className="sub-heading" style={{ marginBottom: 10 }}>{formatDay(booking?.endDate)}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Link>
                                            <div className="change-btn download-btn d-flex" style={{ marginTop: 12 }}>
                                                <a href="#" onClick={() => onDownloadInvoice(booking._id)}>Download Invoice</a>
                                                <a href="#" className="blue-btn" onClick={() => handleCancel(booking)}>Cancel Booking</a>

                                            </div>
                                        </div>

                                    </div>
                                ))}

                            </div>
                        </div>
                        <Modal
                            isOpen={isCancelModalOpen}
                            onRequestClose={closeCancelModal}
                            contentLabel="Cancel Confirmation"
                            className="modal-content"
                            overlayClassName="modal-overlay"
                        >
                            <CancelButton booking={selectedEdit} page="order" closeModal={closeCancelModal} onSuccess={run} />
                        </Modal>
                        {/* Completed Bookings */}
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className="row">
                                {completedBookings?.map(booking => (
                                    <div className="col-lg-4" key={booking._id}>
                                        <Link to={`/view-booking/${booking._id}`}>
                                            <div className="download-outer d-flex">
                                                <div className="download-outer-inner">
                                                    <div className="booking-outer">
                                                        <h5 className="heading" style={{ marginBottom: 10 }}>{booking?.packageName}</h5>
                                                        <p className="sub-heading" style={{ marginBottom: 10 }}>{booking?.duration}</p>
                                                        <p className="heading" style={{ fontSize: 16, marginBottom: 10, color: "#1B1E28" }}>
                                                            {booking?.passengers?.length} Travellers:
                                                            <span>
                                                                {booking.passengers.filter(p => p.age >= 15).length} Adults,
                                                                {booking.numberOfChildren} Child
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="booking-date-outer " style={{ gap: '50px' }}>
                                                        <div className="booking-outer-2">
                                                            <h5 className="heading" style={{ fontSize: 16 }}>{formatDateMonth(booking?.startDate)}</h5>
                                                            <p className="sub-heading" style={{ marginBottom: 10 }}>{formatDay(booking?.startDate)}</p>
                                                        </div>
                                                        <div className="booking-outer-2">
                                                            <h5 className="heading" style={{
                                                                fontSize: 16,
                                                                background: 'rgba(108, 112, 114, 0.15)',
                                                                padding: '3px 10px',
                                                                borderRadius: '50px',
                                                                color: '#6C7072',
                                                            }}
                                                            >{booking?.duration}</h5>
                                                        </div>
                                                        <div className="booking-outer-2">                                                       <h5 className="heading" style={{ fontSize: 16 }}>{formatDateMonth(booking?.endDate)}</h5>
                                                            <p className="sub-heading" style={{ marginBottom: 10 }}>{formatDay(booking?.endDate)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="change-btn download-btn d-flex" style={{ marginTop: 12 }}>
                                                        <a href="#" onClick={() => onDownloadInvoice(booking._id)}>Download Invoice</a>
                                                    </div>
                                                </div>
                                                <div className="payment-outer">
                                                    {/* <a href="#">{booking?.status}</a> */}
                                                    <p>  {booking?.status}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Cancelled Bookings */}
                        <div className="tab-pane fade" id="pills-cancel" role="tabpanel" aria-labelledby="pills-cancel-tab">
                            <div className="row">
                                {cancelBookings?.map(booking => (
                                    <div className="col-lg-6" key={booking._id}>
                                        <Link to={`/view-booking/${booking._id}`}>
                                            <div className="download-outer d-flex">
                                                <div className="download-outer-inner">
                                                    <div className="booking-outer">
                                                        <h5 className="heading" style={{ marginBottom: 10 }}>{booking?.packageName}</h5>
                                                        <p className="sub-heading" style={{ marginBottom: 10 }}>{booking?.duration}</p>
                                                        <p className="heading" style={{ fontSize: 16, marginBottom: 10, color: "#1B1E28" }}>
                                                            {booking?.passengers?.length} Travellers:
                                                            <span>
                                                                {booking.passengers.filter(p => p.age >= 15).length} Adults,
                                                                {booking.numberOfChildren} Child
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="booking-date-outer d-flex" style={{ gap: '50px' }}>
                                                        <div className="booking-outer-2">
                                                            <h5 className="heading" style={{ fontSize: 16 }}>{formatDateMonth(booking?.startDate)}</h5>
                                                            <p className="sub-heading" style={{ marginBottom: 10 }}>{formatDay(booking?.startDate)}</p>
                                                        </div>
                                                        <div className="booking-outer-2">
                                                            <h5 className="heading" style={{
                                                                fontSize: 16,
                                                                background: 'rgba(108, 112, 114, 0.15)',
                                                                padding: '3px 10px',
                                                                borderRadius: '50px',
                                                                color: '#6C7072',
                                                            }}
                                                            >{booking?.duration}</h5>
                                                        </div>
                                                        <div className="booking-outer-2">                                                       <h5 className="heading" style={{ fontSize: 16 }}>{formatDateMonth(booking?.endDate)}</h5>
                                                            <p className="sub-heading" style={{ marginBottom: 10 }}>{formatDay(booking?.endDate)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="change-btn download-btn d-flex" style={{ marginTop: 12 }}>
                                                        <a href="#" onClick={() => onDownloadCancelInvoice(booking._id)}>Download Invoice</a>
                                                    </div>
                                                </div>
                                                <div className="payment-outer">
                                                    {/* <a href="#">{booking?.paymentStatus}</a> */}
                                                    <p>  {booking?.status}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Order;
