import React, { createContext,useContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const user = localStorage.getItem('user');
    setIsLoggedIn(!!user); // Check if user is stored in localStorage
    setUserRole(user?.role || null); // Assuming the role is stored in user.role
  }, []);

  const login = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setIsLoggedIn(true);
    setUserRole(userData.role);
  };

  const logout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setUserRole(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn,userRole, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);

// import React, { createContext, useEffect, useState } from 'react';

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [userRole, setUserRole] = useState(null); // Store user role

//   useEffect(() => {
//     const user = localStorage.getItem('user');
//     const token = localStorage.getItem('authToken');
//     const adminToken = localStorage.getItem('authAdminToken');
    
//     setIsLoggedIn(!!user);
//     setIsAuthenticated(!!token || !!adminToken);
    
//     if (user) {
//       const parsedUser = JSON.parse(user);
//       setUserRole(parsedUser.role); // Assuming user data contains 'role'
//     }
//   }, []);

//   const login = (userData) => {
//     localStorage.setItem('user', JSON.stringify(userData));
//     setIsLoggedIn(true);
    
//     if (userData.userType === 'Admin') {
//       localStorage.setItem('authAdminToken', userData.token);
//       setIsAuthenticated(true);
//     } else {
//       localStorage.setItem('authToken', userData.token);
//       setIsAuthenticated(true);
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('user');
//     localStorage.removeItem('authToken');
//     localStorage.removeItem('authAdminToken');
//     setIsLoggedIn(false);
//     setIsAuthenticated(false);
//     setUserRole(null);
//   };

//   return (
//     <AuthContext.Provider value={{ isLoggedIn, isAuthenticated, userRole, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


// // import React, { createContext, useEffect, useState } from 'react';

// // export const AuthContext = createContext();

// // export const AuthProvider = ({ children }) => {
// //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// //   const [isAuthenticated, setIsAuthenticated] = useState(false);

// //   useEffect(() => {
// //     const user = localStorage.getItem('user');
// //     const token = localStorage.getItem('authToken');
// //     setIsLoggedIn(!!user);
// //     setIsAuthenticated(!!token);
// //   }, []);

// //   const login = (userData) => {
// //     localStorage.setItem('user', JSON.stringify(userData));
// //     setIsLoggedIn(true);
// //     setIsAuthenticated(true);
// //   };

// //   const logout = () => {
// //     localStorage.removeItem('user');
// //     localStorage.removeItem('authToken');
// //     setIsLoggedIn(false);
// //     setIsAuthenticated(false);
// //   };

// //   return (
// //     <AuthContext.Provider value={{ isLoggedIn, isAuthenticated, login, logout }}>
// //       {children}
// //     </AuthContext.Provider>
// //   );
// // };
