import requests from "./httpService";

const BookingServices = {

    getBooking : async () =>{
        return requests.get(`/booking`);
    },
    getMyBooking : async () =>{
        return requests.get(`/booking/my`);
    },
    getMyBookingById : async (id) =>{
        return requests.get(`/booking/${id}`);
    },
    updateBooking : async (id,body) =>{
        return requests.put(`/booking/${id}`,body);
    },
    cancelBooking : async (id,body) =>{
        console.log("<<<<<",id)
        return requests.patch(`/booking/${id}/cancel`,body);
    },
    updateBookingStatus: async (id,body) =>{
        // console.log(">>>",body)
        return requests.patch(`/booking/updatestatus/${id}`,body);
    },
    downloadInvoice: async (id) =>{
        // console.log(">>>",body)
        return requests.get(`/booking/download-invoice/${id}`);
    },
    downloadCancelInvoice: async (id) =>{
        // console.log(">>>",body)
        return requests.get(`/booking/cancel/download-invoice/${id}`);
    },

    createBooking : async (body) =>{
        // console.log(body)
        return requests.post(`/booking`,body);
    },
    verifyPayment : async (body) =>{
        // console.log("body:",body)
        return requests.post(`/booking/verify-payment`,body);
    },
    deleteBooking : async (id) =>{
        return requests.delete(`/booking/${id}`);
    }
  


}

export default BookingServices;