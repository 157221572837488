import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet,Navigate  } from 'react-router-dom';
import RouteWrapper from './RouteWrapper';
// import { AuthProvider } from './context/AuthContextLogin'
import { AuthProvider,useAuth  } from './AuthContext'
// Layouts
import AdminLayout from './layouts/AdminLayout';
import FrontendLayout from './layouts/FrontendLayout';

// Admin Components
import AdminLogin from './component/Auth/Login';
import Dashboard from './component/Dashboard';
import UserManager from './component/UserManager';
import Notification from './component/Notification';
import AddNotification from './component/Add Model/AddNotification';
import Destination from './component/Destination';
import AddDestination from './component/Add Model/AddDestination';
import AddHomeSlider from './component/Add Model/AddHomeSlider';
import AddBanner from './component/Add Model/AddBanner';
import UserAdd from './component/Add Model/UserAdd';
import Banner from './component/AppBanner';
import HomeSlider from './component/HomeSlider';
import Contact from './component/Contact';
import Profile from './component/Profile';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermCondition from './component/TermAndCondition';
import Feedback from './component/Feedback';
import AddAdmin from './component/Add Model/AddAdmin';
import AdminUser from './component/AdminUser';
import AddPackage from './component/Add Model/AddPackage';
import Package from './component/Package';
import Booking from './component/Booking';
import PushNotification from './component/PushNotification';
import PushNotificationList from './component/PushNotificationList';
// import Earning from './component/Earning';
import UserPermission from './component/UserPermission';
import RefundPolicy from './component/RefundPolicy';
import AppSettings from './component/AppSettings';
import EmailNotification from './component/EmailNotification';
import Vendor from './component/Vendor';
import InvoiceDetail from './component/Invoice';
// forntend
import FrontEndHome from './component/FrontEnd/home';
import Refund from './component/FrontEnd/Refund';
import Terms from './component/FrontEnd/Terms';
import PrivacyFront from './component/FrontEnd/Privacy';
import Login from './component/FrontEnd/Auth/login';
import Signup from './component/FrontEnd/Auth/Signup';
import PrivateRoute from './component/PrivateRoute';
import ContactUs from './component/FrontEnd/ContactUs';
import FrontEndNotification from './component/FrontEnd/Notification';
import FrontEndBooking from './component/FrontEnd/Booking';
import FrontEndOrder from './component/FrontEnd/Order';
import PackageDetails from './component/FrontEnd/PackageDetails';
import Wishlist from './component/FrontEnd/Wishlist';
import AllPackage from './component/FrontEnd/AllPackage';
import ViewBooking from './component/FrontEnd/ViewBooking';
import DestinationPackage from './component/FrontEnd/DestinationPackage';


function ProtectedAdminRoute({ children }) {
  const { isLoggedIn} = useAuth();
  const userRole = localStorage.getItem('userRole');
console.log(">>>>",userRole);
  if ( userRole !== 'Admin') {
    return <Navigate to="/admin/login" replace />;
  }

  return children;
}

function App() {
  const [searchValue, setSearchValue] = React.useState('');
  // const isAuthenticated = true;

  const handleSearchChange = (value) => {
      localStorage.setItem('searchValue', value.name);
      setSearchValue(value);
      // console.log("value",value)
  };

  return (
    <AuthProvider>
      <Router>
        <RouteWrapper>
          <Routes>
            {/* Frontend Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />

            <Route path="/refund" element={<Refund />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<PrivacyFront />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/notification" element={<FrontEndNotification />} />
            <Route path="/booking/:id" element={<FrontEndBooking />} />
            <Route path="/order" element={<FrontEndOrder />} />
            <Route path="/view-booking/:id" element={<ViewBooking />} />
            <Route path="/package-details/:id" element={<PackageDetails />} />
            <Route path="/destination-package/:id" element={<DestinationPackage />} />
            <Route path="/tour-package" element={<AllPackage />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route element={<FrontendLayout onSearch ={handleSearchChange}/>}>
            <Route path="/" element={<FrontEndHome searchValue={searchValue} />} />
            
            </Route>
            <Route path="/home" element={<PrivateRoute><FrontEndHome /></PrivateRoute> } />
            {/* Admin Login Route */}
            <Route path="/admin/login" element={<AdminLogin />} />

            {/* Protected Admin Routes */}
            {/* {isAuthenticated && ( */}
              {/* <Route path="/admin" element={<AdminLayout />}> */}
              <Route
              path="/admin/*"
              element={
                <ProtectedAdminRoute>
                  <AdminLayout />
                </ProtectedAdminRoute>
              }
            >
                <Route path="dashboard" element={<Dashboard />} />
                {/* Define all admin routes that require 'Admin' role */}
                <Route path="user-add" element={<UserAdd />} />
                <Route path="vendor-list" element={<Vendor />} />
                <Route path="contact" element={<Contact />} />
                <Route path="invoice-details" element={<InvoiceDetail />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="package" element={<Package />} />
                <Route path="booking" element={<Booking />} />
                <Route path="add-package" element={<AddPackage />} />
                <Route path="term-and-condition" element={<TermCondition />} />
                <Route path="refund-policy" element={<RefundPolicy />} />
                <Route path="user-permission" element={<UserPermission />} />
                <Route path="user-manager" element={<UserManager />} />
                <Route path="notification-list" element={<Notification />} />
                <Route path="add-notification" element={<AddNotification />} />
                <Route path="destination-list" element={<Destination />} />
                <Route path="add-destination" element={<AddDestination />} />
                <Route path="homeslider" element={<HomeSlider />} />
                <Route path="add-home-slider" element={<AddHomeSlider />} />
                <Route path="push-notification" element={<PushNotification />} />
                <Route path="push-notification-list" element={<PushNotificationList />} />
                <Route path="app-settings" element={<AppSettings />} />
                <Route path="email-notification" element={<EmailNotification />} />
                <Route path="admin-user" element={<AdminUser />} />
                <Route path="add-admin-user" element={<AddAdmin />} />
                <Route path="profile" element={<Profile />} />
                <Route path="add-banner" element={<AddBanner />} />
                <Route path="app-banner-list" element={<Banner />} />
                {/* <Route path="earning" element={<Earning />} /> */}
              </Route>
            {/* )} */}
          </Routes>
        </RouteWrapper>
      </Router>
    </AuthProvider>
  );
}

export default App;
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
// import RouteWrapper from './RouteWrapper';
// // import { AuthProvider } from './context/AuthContextLogin'
// import { AuthProvider } from './AuthContext'
// // Layouts
// import AdminLayout from './layouts/AdminLayout';
// import FrontendLayout from './layouts/FrontendLayout';

// // Admin Components
// import AdminLogin from './component/Auth/Login';
// import Dashboard from './component/Dashboard';
// import UserManager from './component/UserManager';
// import Notification from './component/Notification';
// import AddNotification from './component/Add Model/AddNotification';
// import Destination from './component/Destination';
// import AddDestination from './component/Add Model/AddDestination';
// import AddHomeSlider from './component/Add Model/AddHomeSlider';
// import AddBanner from './component/Add Model/AddBanner';
// import UserAdd from './component/Add Model/UserAdd';
// import Banner from './component/AppBanner';
// import HomeSlider from './component/HomeSlider';
// import Contact from './component/Contact';
// import Profile from './component/Profile';
// import PrivacyPolicy from './component/PrivacyPolicy';
// import TermCondition from './component/TermAndCondition';
// import Feedback from './component/Feedback';
// import AddAdmin from './component/Add Model/AddAdmin';
// import AdminUser from './component/AdminUser';
// import AddPackage from './component/Add Model/AddPackage';
// import Package from './component/Package';
// import Booking from './component/Booking';
// import PushNotification from './component/PushNotification';
// import PushNotificationList from './component/PushNotificationList';
// // import Earning from './component/Earning';
// import UserPermission from './component/UserPermission';
// import RefundPolicy from './component/RefundPolicy';
// import AppSettings from './component/AppSettings';
// import EmailNotification from './component/EmailNotification';
// import Vendor from './component/Vendor';
// // forntend
// import FrontEndHome from './component/FrontEnd/home';
// import Refund from './component/FrontEnd/Refund';
// import Terms from './component/FrontEnd/Terms';
// import PrivacyFront from './component/FrontEnd/Privacy';
// import Login from './component/FrontEnd/Auth/login';
// import Signup from './component/FrontEnd/Auth/Signup';
// import PrivateRoute from './component/PrivateRoute';
// import ContactUs from './component/FrontEnd/ContactUs';
// import FrontEndNotification from './component/FrontEnd/Notification';
// import FrontEndBooking from './component/FrontEnd/Booking';
// import FrontEndOrder from './component/FrontEnd/Order';
// import PackageDetails from './component/FrontEnd/PackageDetails';
// import Wishlist from './component/FrontEnd/Wishlist';
// import AllPackage from './component/FrontEnd/AllPackage';
// import ViewBooking from './component/FrontEnd/ViewBooking';
// import DestinationPackage from './component/FrontEnd/DestinationPackage';

// function App() {
//   const [searchValue, setSearchValue] = React.useState('');
//   const isAuthenticated = true;

//   const handleSearchChange = (value) => {
//       localStorage.setItem('searchValue', value.name);
//       setSearchValue(value);
//       // console.log("value",value)
//   };

//   return (
//     <AuthProvider>
//       <Router>
//         <RouteWrapper>
//           <Routes>
//             {/* Frontend Routes */}
//             <Route path="/login" element={<Login />} />
//             <Route path="/signup" element={<Signup />} />

//             <Route path="/refund" element={<Refund />} />
//             <Route path="/terms" element={<Terms />} />
//             <Route path="/privacy" element={<PrivacyFront />} />
//             <Route path="/contactUs" element={<ContactUs />} />
//             <Route path="/notification" element={<FrontEndNotification />} />
//             <Route path="/booking/:id" element={<FrontEndBooking />} />
//             <Route path="/order" element={<FrontEndOrder />} />
//             <Route path="/view-booking/:id" element={<ViewBooking />} />
//             <Route path="/package-details/:id" element={<PackageDetails />} />
//             <Route path="/destination-package/:id" element={<DestinationPackage />} />
//             <Route path="/tour-package" element={<AllPackage />} />
//             <Route path="/wishlist" element={<Wishlist />} />
//             <Route element={<FrontendLayout onSearch ={handleSearchChange}/>}>
//             <Route path="/" element={<FrontEndHome searchValue={searchValue} />} />
            
//             </Route>
//             <Route path="/home" element={<PrivateRoute><FrontEndHome /></PrivateRoute> } />
//             {/* Admin Login Route */}
//             <Route path="/admin/login" element={<AdminLogin />} />

//             {/* Protected Admin Routes */}
//             {isAuthenticated && (
//               <Route path="/admin" element={<AdminLayout />}>
//                 <Route path="dashboard" element={<Dashboard />} />
//                 {/* Define all admin routes that require 'Admin' role */}
//                 <Route path="user-add" element={<UserAdd />} />
//                 <Route path="vendor-list" element={<Vendor />} />
//                 <Route path="contact" element={<Contact />} />
//                 <Route path="privacy-policy" element={<PrivacyPolicy />} />
//                 <Route path="package" element={<Package />} />
//                 <Route path="booking" element={<Booking />} />
//                 <Route path="add-package" element={<AddPackage />} />
//                 <Route path="term-and-condition" element={<TermCondition />} />
//                 <Route path="refund-policy" element={<RefundPolicy />} />
//                 <Route path="user-permission" element={<UserPermission />} />
//                 <Route path="user-manager" element={<UserManager />} />
//                 <Route path="notification-list" element={<Notification />} />
//                 <Route path="add-notification" element={<AddNotification />} />
//                 <Route path="destination-list" element={<Destination />} />
//                 <Route path="add-destination" element={<AddDestination />} />
//                 <Route path="homeslider" element={<HomeSlider />} />
//                 <Route path="add-home-slider" element={<AddHomeSlider />} />
//                 <Route path="push-notification" element={<PushNotification />} />
//                 <Route path="push-notification-list" element={<PushNotificationList />} />
//                 <Route path="app-settings" element={<AppSettings />} />
//                 <Route path="email-notification" element={<EmailNotification />} />
//                 <Route path="admin-user" element={<AdminUser />} />
//                 <Route path="add-admin-user" element={<AddAdmin />} />
//                 <Route path="profile" element={<Profile />} />
//                 <Route path="add-banner" element={<AddBanner />} />
//                 <Route path="app-banner-list" element={<Banner />} />
//                 {/* <Route path="earning" element={<Earning />} /> */}
//               </Route>
//             )}
//           </Routes>
//         </RouteWrapper>
//       </Router>
//     </AuthProvider>
//   );
// }

// export default App;